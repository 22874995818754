import { Box } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { useLessonStore } from '../../../store/lesson/LessonStore';
import { useUiStore } from '../../../store/uiStore';
import { useAuthStore } from '../../../store/auth/AuthStore';
import { SvgImage } from '../../Icon/SvgImage';
import { KnownIcons } from '../../Icon/KnownIcons';

interface StyledIconWrapperParams {
  collapsed: boolean;
  interfaceClr: string;
  chosen: boolean;
}

const StyledIconWrapper = styled.div<StyledIconWrapperParams>`
  //&:not(:last-child){
  //  margin-right: -25px;
  //  &:hover {
  //    margin-right: -10px;
  //    margin-left: 15px;
  //  }
  //}
  //&:last-child{
  //  //margin-right: -5px;
  //}
  margin: 0 2px;
  cursor: pointer;
  width: ${p => p.collapsed?2:42}px;
  display: grid;
  align-items: center;
  transition: 300ms;
 
  & > img {
    border-radius: 50%;
    ${({ theme, interfaceClr, chosen }) =>
            chosen && `outline: 2px solid ${theme.currentTheme[interfaceClr]} !important;`}
  }
`;

export function StudentsIcons() {
  const { users, isStudentLayout } = useLessonStore();
  const { user } = useAuthStore();
  const { interfaceClr } = useUiStore();
  const { monitoringStudentId, toggleStudentMonitoringMode } = useLessonStore();

  if (isStudentLayout)
    return null;

  return (
    <Box ml={'auto'} mr={1} display={'flex'} gridGap={0}>
      {monitoringStudentId && <div style={{
        paddingRight: 10,
        font: "var(--font-normal)",
        height: 42,
        display: 'flex',
        alignItems:"center",
        color: "var(--color-icon-main)"
      }}>
        {users.find(u => u.id === monitoringStudentId)?.displayName}
      </div>}
      {users.filter(u => u.id !== user.id)
        .map(({ handUp, displayName, id , imageUrl}) => (
        <StyledIconWrapper
          collapsed={monitoringStudentId && id !== monitoringStudentId}
          interfaceClr={interfaceClr}
          chosen={monitoringStudentId === id}
          onClick={() => toggleStudentMonitoringMode(id)}
          key={id}
          title={displayName}
        >
          <img width={42} key={id} src={imageUrl} alt='person' style={{gridColumn: 1, gridRow: 1}}/>
          {handUp && <SvgImage size={24} icon={KnownIcons.hand} active r={16} style={{
            margin: "-30px 0 0 10px",
            gridColumn:1,
            gridRow:1
          }} />}
        </StyledIconWrapper>
      ))}
    </Box>
  );
}

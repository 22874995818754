import React, { useState } from 'react';
import styled from 'styled-components';
import Input from '../../LowLevelComponents/Input';
import { Button } from '../../LowLevelComponents/Button';
import {
  CloseIcon,
  IconWrapper,
  StyledInputWrapper as CommonInputWrapper,
  StyledModalBody,
  StyledModalFooter,
  StyledModalHeader,
  StyledModalTitle,
} from '../common';
import { InputAdornment } from '@material-ui/core';

import { useModalStore } from '../../../store/modalStore';
import { SvgImage } from '../../Icon/SvgImage';
import { KnownIcons } from '../../Icon/KnownIcons';

const StyledInputWrapper = styled(CommonInputWrapper)`
  display: grid;
  grid-template-columns: 90% 10%;
`;

export interface ITextEditorLinkModalProps {
  previousUrl: string;
  setLink: (url: string) => void;
}

export function Modal_TextEditorLink(props: ITextEditorLinkModalProps) {
  let {
    previousUrl,
    setLink,
  } = props;
  const { closeModal } = useModalStore();
  // Component state
  const [value, setValue] = useState<string>(previousUrl ? previousUrl : '');

  // Handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

  const handleCancelButton = (e) => closeModal();

  const handleSaveButton = (e) => {
    setLink(value);
    closeModal();
  };
  return (
    <>
      <StyledModalHeader>
        <StyledModalTitle>Добавьте ссылку</StyledModalTitle>
        <CloseIcon />
      </StyledModalHeader>
      <StyledModalBody>
        <StyledInputWrapper>
          <Input
            autoFocus
            disabled={false}
            label='Ссылка'
            fullWidth
            value={value}
            onChange={handleChange}
            endAdornment={<InputAdornment style={{ paddingRight: '2px', opacity: value?1:0 }} position='start'>
              <SvgImage withHover icon={KnownIcons.cross} onClick={() => setValue('')}/>
            </InputAdornment>}
          />
          <IconWrapper>
            <SvgImage withHover icon={KnownIcons.copy}/>
          </IconWrapper>
        </StyledInputWrapper>
      </StyledModalBody>
      <StyledModalFooter mobileTwoButtons>
        <Button type='secondary' onClick={handleCancelButton}>
          Отменить
        </Button>
        <Button type='primary' onClick={handleSaveButton}>
          Сохранить
        </Button>
      </StyledModalFooter>
    </>
  );
}


import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Logo, LogoSize } from '../Icon/Logo';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  max-width: 375px;
  min-width: 375px;
  margin: 0 auto;
  padding-top: 120px;
`;

export function LoginPageContainer (props:PropsWithChildren<{showLogo?: boolean, logoSize?: LogoSize}>) {
  return <StyledContainer>
    {props.showLogo !== false && <Logo size={props.logoSize ? props.logoSize : LogoSize.large} />}
    {props.children}
  </StyledContainer>
}
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button } from '../../components/LowLevelComponents/Button';
import PasswordInput from '../../components/LowLevelComponents/PasswordInput';
import { InputWrapper, OneButtonWrapper,  TwoButtonsWrapper } from './common';
import { useAuthStore } from '../../store/auth/AuthStore';
import { ServerApi } from '../../transport/ServerApi';
import { IProfile } from '@chessclub/grpc_wrapper';
import { useLocalization } from '../../l10n/UseLocalization';
import { IAuthFormErrors } from '../../interfaces/auth';
import { useUiStore } from '../../store/uiStore';
import { StyledButton, StyledMessage } from '../RegisterPage/RegisterSteps';

interface IProps {
  onChange(err: any): void;
  profile: IProfile;
}

export const SecurityTab: FunctionComponent<IProps> = ({ onChange, profile }) => {

  const { user } = useAuthStore();
  const {lang: siteLang} = useUiStore();
  const { auth, lesson } = useLocalization();


  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [passwordCurrent, setPasswordCurrent] = useState('');
  const initialErrors: IAuthFormErrors = { passwordCurrent:false, password: false, passwordRepeat: false };
  const [errors, setErrors] = useState(initialErrors);
  const [authError, setAuthError] = useState('');
  const [error, setError] = useState('');

  const [isCancelButtonEnabled, setCancelButtonEnabled] = useState<boolean>(false);
  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);
  const [inProgress, setInProgress] = useState<boolean>(false);

  useEffect(() => {
    let errors: IAuthFormErrors = {};
    setError("");

    let notMatchOrEmpty = password && passwordRepeat && password !== passwordRepeat;
    if (notMatchOrEmpty) {
      errors.password = true;
      errors.passwordRepeat = true;

      setError('password_not_match');
    }

    errors.passwordCurrent = !!authError;

    setCancelButtonEnabled(!!password || !!passwordRepeat || !!passwordCurrent);
    setSaveButtonDisabled(!password || !passwordRepeat || !passwordCurrent || notMatchOrEmpty);

    setErrors(errors);
  }, [password, passwordRepeat])


  useEffect(() => {
    let errors: IAuthFormErrors = {};
    setError("");

    if(authError) {
      console.log("authError: " + authError)
      if (authError === "WRONG_PASSWORD") {
        errors.passwordCurrent = true;
      }
      else if (authError === "WEAK_PASSWORD") {
        errors.password = true;
        errors.passwordRepeat = true;
      }
    }

    setErrors(errors);
    setCancelButtonEnabled(!!password || !!passwordRepeat || !!passwordCurrent);
    setSaveButtonDisabled(true)
  }, [authError])


  useEffect(() => {
    let errors: IAuthFormErrors = {};

    errors.passwordCurrent = false;
    setAuthError('');
    setErrors(errors);

    setCancelButtonEnabled(!!password || !!passwordRepeat || !!passwordCurrent);
    setSaveButtonDisabled(!password || !passwordRepeat || !passwordCurrent || password !== passwordRepeat);
  }, [passwordCurrent])


  const onSave = async () => {
    const result = await ServerApi.accessService.completeChangePassword(user.email, password, passwordCurrent);
    if(result) {
      setAuthError(result)
    }
    else {
      console.log("completeChangePassword: Ok!")
      await onCancel();
    }
  };

  const onCancel = async () => {
    setPassword('');
    setPasswordRepeat('');
    setPasswordCurrent('');
    setError('');

    setCancelButtonEnabled(false);
    setSaveButtonDisabled(true);
  };


  return (
    <div style={{ width: '540px' }}>
      <StyledMessage>
        {auth.change_password}
      </StyledMessage>
      <InputWrapper>
        <PasswordInput
          showErrorText={true}
          errorText={auth[error] || auth.errors[authError]}
          label={auth.current_password}
          value={passwordCurrent}
          onChange={(e) => {
            setPasswordCurrent(e.target.value);
          }}
          error={errors.passwordCurrent}
          fullWidth
        />
      </InputWrapper>
      <InputWrapper>
        <PasswordInput
          label={auth.password}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          error={errors.password}
          fullWidth
        />
      </InputWrapper>
      <InputWrapper>
        <PasswordInput
          label={auth.repeat_password}
          value={passwordRepeat}
          onChange={(e) => {
            setPasswordRepeat(e.target.value);
          }}
          error={errors.passwordRepeat}
          fullWidth
        />
      </InputWrapper>

      <TwoButtonsWrapper>
        <StyledButton
          fullWidth
          disabled={!isCancelButtonEnabled || inProgress}
          onClick={async () => {
            setInProgress(true)
            await onCancel()
            setInProgress(false)
          }}>
          {lesson.cancel}
        </StyledButton>

        <StyledButton
          fullWidth
          disabled={isSaveButtonDisabled || inProgress}
          onClick={async () => {
            setInProgress(true)
            await onSave()
            setInProgress(false)
          }}>
          {lesson.save}
        </StyledButton>

      </TwoButtonsWrapper>
    </div>
  );
};



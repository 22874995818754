import React from 'react';
import { Preloader } from '../../components';
import { EnterLessonInitialPhase } from './EnterLessonInitialPhase';
import { useAuthStore } from '../../store/auth/AuthStore';
import { NotFoundPage } from '../NotFoundPage/NotFoundPage';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { LoginPageContainer } from '../../components/LoginPageContainer/LoginPageContainer';
import { Grid } from '@material-ui/core';
import { TextLink } from '../../components/PageStyledComponents/TextLink';
import { useLocalization } from '../../l10n/UseLocalization';


export function EnterLessonPage() {
  const { auth } = useLocalization();
  const { lessonAuthSettings, user } = useAuthStore();

  if (!lessonAuthSettings && !user)
    return <Preloader />;

  if (!lessonAuthSettings && user)
    return <NotFoundPage />

  if (lessonAuthSettings && lessonAuthSettings.error)
    return <NotFoundPage />

  return <PageTemplate header={false} showLogo={true}>
    <LoginPageContainer showLogo={true}>
      <Grid container justifyContent='center'>
        {lessonAuthSettings && (
          <EnterLessonInitialPhase
            passwordInit={lessonAuthSettings.password} />
        )}
        {/*{phase === 'captcha' && (*/}
        {/*  <CaptchaStep*/}
        {/*    email={'userId'}*/}
        {/*    onSuccess={() => lessonAuth(studentName)}*/}
        {/*    confirmButtonText='Продолжить'*/}
        {/*  />*/}
        {/*)}*/}
      </Grid>
      <Grid container justifyContent='center' direction='row' style={{ paddingTop: 15 }}>
        <TextLink linkText={auth.sign_up} to={'/auth/register/' + document.location.pathname.split('/').pop()} />
        <div style={{ margin: '0 15px' }}></div>
        <TextLink linkText={auth.login} to={'/auth/login/' + document.location.pathname.split('/').pop()} />
      </Grid>
    </LoginPageContainer>
  </PageTemplate>;
}



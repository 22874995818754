import { L10n } from "./L10n";

export const Lang_en: L10n = {
  "landing": {
    "mainTitle": "Learning<br/>at a new level",
    "mainSubTitle": "<br/>a platform for distance chess learning",
    'aboutUs': 'About the platform',
    'aboutUsText': '<b>ChessClub Training is a platform for those for whom chess is more than just a game!</b><br/><br/>We make the learning process as<br/>comfortable, effective, and engaging<br/>for both teachers and students as possible',
    'ourCapabilitiesTitle': 'Our capabilities',
    'ourCapabilitiesElements': [
      ['https://chessclub.spb.ru/landing/img/crown.svg', 'Conducting both individual and group classes'],
      ['https://chessclub.spb.ru/landing/img/crown.svg', 'Implementing new game forms in teaching children chess (gamification)'],
      ['https://chessclub.spb.ru/landing/img/crown.svg', 'All necessary teaching materials are gathered in one place'],
      ['https://chessclub.spb.ru/landing/img/crown.svg', 'Extended tools for teaching'],
      ['https://chessclub.spb.ru/landing/img/crown.svg', 'Remote learning with a sense of presence'],
    ],
    'ourPlatformTitle': 'Our platform is for you if you:',
    'ourPlatformElements': [
      ['https://chessclub.spb.ru/landing/img/knight_black.svg', 'https://chessclub.spb.ru/landing/img/knight_white.svg', 'Are freelancing in chess education'],
      ['https://chessclub.spb.ru/landing/img/rook_black.svg', 'https://chessclub.spb.ru/landing/img/rook_white.svg', 'Are a teacher at a chess club or a professional chess coach'],
      ['https://chessclub.spb.ru/landing/img/king_black.svg', 'https://chessclub.spb.ru/landing/img/king_white.svg', 'Conduct master classes, lectures, or intensives on chess'],
      ['https://chessclub.spb.ru/landing/img/queen_black.svg', 'https://chessclub.spb.ru/landing/img/queen_white.svg', 'Want your child to think logically and learn to play chess'],
      ['https://chessclub.spb.ru/landing/img/pawn_black.svg', 'https://chessclub.spb.ru/landing/img/pawn_white.svg', 'Want to find a coach and learn to play chess'],
    ],
    'ourAdvantagesTitle': 'Our advantages',
    'ourAdvantagesElements': [
      ['https://chessclub.spb.ru/landing/img/pawns-6.svg', 'Ability to conduct group online classes in conference mode'],
      ['https://chessclub.spb.ru/landing/img/board.svg', 'Unique chessboard with customization options for different tasks'],
      ['https://chessclub.spb.ru/landing/img/arrows.svg', 'Virtual pointer with synchronized cursor and piece movements'],
      ['https://chessclub.spb.ru/landing/img/pawns-3.svg', 'Tools for conducting classes with different age categories'],
      ['https://chessclub.spb.ru/landing/img/ai.svg', 'Application of artificial intelligence technologies'],
      ['https://chessclub.spb.ru/landing/img/pc.svg', 'Adaptive interfaces for PC, laptop, smartphone, tablet, mobile version'],
      ['https://chessclub.spb.ru/landing/img/two-knights.svg', 'Ability to conduct online tournaments<br/>(in development)'],
      ['https://chessclub.spb.ru/landing/img/server.svg', 'Synergy with TimeWeb services (reliable hosting on servers)'],
    ],
    'ourExpertsTitle': 'Our experts',
    'ourExpertsElements': [
      ['https://chessclub.spb.ru/landing/img/vladimir-bykov.png', '<b>Vladimir Bykov</b><br/><br/>Executive Director of the St. Petersburg Chess Federation. Captain of the "Bronze Horseman" team. National category sports referee in chess.'],
      ['https://chessclub.spb.ru/landing/img/ekaterina-sudoplatova.png', '<b>Ekaterina Sudoplatova</b><br/><br/>Women’s Grandmaster. International Master. Two-time champion of Russia and world champion among girls, European champion, member of the Russian Olympic team, champion of Russia among women, multiple champion of St. Petersburg. Coach, additional education teacher.'],
      ['https://chessclub.spb.ru/landing/img/alina-balayan.png', '<b>Alina Balayan</b><br/><br/>Women’s International Master. Sports Master of Russia in chess. Champion of Russia in rapid among women, champion of Russia as part of a team, multiple champion of St. Petersburg. Coach, additional education teacher.'],
      ['https://chessclub.spb.ru/landing/img/valeriy-popov.png', '<b>Valeriy Popov</b><br/><br/>Grandmaster. International Master. European Champion. Highest category coach. Senior coach of the St. Petersburg State Budgetary Institution of Additional Education in Chess.'],
      ['https://chessclub.spb.ru/landing/img/dina-belenkaya.png', '<b>Dina Belenkaya</b><br/><br/>Women’s Grandmaster. Multiple prize-winner of the Russian Championship as part of a team, multiple champion of St. Petersburg. Chess teacher.'],
      ['https://chessclub.spb.ru/landing/img/anastasia-bondaruk.png', '<b>Anastasia Bondaruk</b><br/><br/>Women’s Grandmaster. International Master. World rapid champion, multiple champion of Russia, multiple champion of St. Petersburg, member of the Russian national team.'],
    ],
    'imagesSlider': [
      ['Онлайн занятие', 'https://chessclub.spb.ru/landing/img/online-black.png', 'https://chessclub.spb.ru/landing/img/online-white.png'],
      ['Оффлайн занятие', 'https://chessclub.spb.ru/landing/img/online-black.png', 'https://chessclub.spb.ru/landing/img/offline-white.png'],
      ['Редактирование задания', 'https://chessclub.spb.ru/landing/img/online-black.png', 'https://chessclub.spb.ru/landing/img/edit-white.png'],
    ]
  },
  "footer": {
    "help": "Help",
    "termsOfService": "Terms of service",
    "contacts": "Contacts",
    "supportUs": "Support us",
  },
  "rooms": {
    "rooms": "Rooms",
    "new_room": "New room",
    "edit_room": "Editing room",
    "name": "Name",
    "description": "Description",
    "link": "link",
    "external_view": "Еxternal view of the student's room",
    "password_optional": "Password (optional)",
    "trainees": "Trainees",
    "nothing_found": "Nothing found",
    "search_by_site": "Search by site",
    "more_characters": " (enter 3 or more characters)",
  },
  "lesson": {
    "newTask": "New task",
    "chain": "Chain",
    "table": "Table",
    "clear": "Clear",
    "room": "Room",
    "defaultRoom": "Default room",
    "ok": "Ok",
    "empty": "Nothing found",
    "sizeHuge": "Huge",
    "sizeLarge": "Large",
    "sizeStandard": "Standard",
    "markersBlue": "Blue marker",
    "markersGreen": "Green marker",
    "markersOff": "Turn off markers",
    "markersRed": "Red marker",
    "markersDelete": "Delete markers",
    "withoutTitles": "Without titles",
    "titlesAtCellCorners": "At cell corners",
    "titlesInEveryCell": "In every cell",
    "titlesAroundChessboard": "Around chessboard",
    "standardView": "Standard",
    "allChessboardsView": "All chessboards",
    "allCamsView": "All cams",
    "size": "Size",
    "exit": "Exit",
    "knowledges": "Knowledge base",
    "materials": "Мaterials",
    "my_materials": "My materials",
    "rooms": "Rooms",
    "profile": "Profile",
    "edit": "Edit",
    "paste": "Paste",
    "copy": "Copy",
    "save": "Save",
    "cancel": "Cancel",
    "delete": "Delete",
    "add": "Add",
    "waitForTrainer": "Wait for trainer",
    "chat": "Chat",
    "cams": "Cams",
    "online": "Online",
    "view": "View",
    "demonstration": "Demonstration",
    "settings": "Settings",
    "offline": "Offline",
    "black": "Black",
    "white": "White",
    "tasks": "Tasks",
    "moves": "Moves",
    "activeLessons": "Active lessons",
    "activeLesson": "Active lesson",
    "student": "Student",
    "trainer": "Trainer",
    "lesson": "Lesson",
    "lessons": "Lessons",
    "beginning": "To the beginning",
    "name": "Name",
    "task": "Task with answer",
    "game": "Game with heroes",
    "question": "Question with description",
    "engine": "Game with computer",
    "edit_task": "Editing task",
    "search": "Search",
    "edit_material": "Editing material",
    "new_topic": "Add new topic",
    "reset": "Reset",
  },
  "captcha" : {
    "language": "English",
    'task' : "Please complete the task so that we can know that you are a real person:",
    "errors": {
      "NO_ERROR": "No error found",
      "INVALID_PARAMS": "Invalid captcha params",
      'WRONG_PROTOBUF_MESSAGE': 'Wrong protobuf message',
      "CAPTCHA_NOT_FOUND": "Captcha not found",
      "CAPTCHA_ALREADY_EXISTS": "Invalid captcha params",
      "WRONG_CAPTCHA_EMAIL": "'Wrong captcha email",
      "CAPTCHA_IS_EXPIRED": "'Captcha is expired",
      "ANSWER_IS_EXPIRED": "'Answer is expired",
      "WRONG_CAPTCHA_ANSWER": "Wrong captcha answer",
      "WRONG_FEN_RANDOMIZED": "Wrong FEN randomized"
    },
    "questions": {
      "Find the white pawns": "Find the white pawns",
      "Find the black pawns": "Find the black pawns",
      "Find the pawns": "Find the pawns",
      "Find the white knights": "Find the white knights",
      "Find the black knights": "Find the black knights",
      "Find the knights": "Find the knights",
      "Find the white bishops": "Find the white bishops",
      "Find the black bishops": "Find the black bishops",
      "Find the bishops": "Find the bishops",
      "Find the white rooks": "Find the white rooks",
      "Find the black rooks": "Find the black rooks",
      "Find the rooks": "Find the rooks",
      "Find the white queens": "Find the white queens",
      "Find the black queens": "Find the black queens",
      "Find the queens": "Find the queens",
      "Find the kings": "Find the kings",
    },
  },
  "auth": {
    "new_password": "New password",
    "repeat_new_password": "Repeat new password",
    "consider_new_password": "Create a new password and enter the code sent to your email for confirmation",
    "confirm": "Confirm",
    "agree_with": "I agree with",
    "terms": "Terms of service",
    "do_sign_up": "Sign up",
    "continue": "Continue",
    "code": "Code from email",
    'resend_code': 'Resend code',
    "email_to_restore": "Enter email address to restore access",
    "receive_code_in_mail": "Get code in email",
    "repeat_password": "Repeat password",
    "login_with": "Login with",
    "login": "Login",
    "forgot_password": "Restore password",
    "or": "or",
    "email": "Email",
    'wrong_email': 'Wrong email',
    "password": "Password",
    "password_not_match":"The passwords do not match",
    "no_account_yet": "Don't have an account yet?",
    "sign_up": "Sign up",
    "yandex": "Yandex",
    "ok": "Ok",
    "mail": "Mail.ru",
    "fb": "Facebook",
    "twitter": "Twitter",
    "vk": "Vkontakte",
    "google": "Google",
    "choose_lg": "Choose your language",
    "language": "English",
    "home_page": "to the home page",
    "page_not_found": "Unfortunately, the page was not found...",
    "page_not_found_title": "Use the menu at the top of the screen or go back ",
    "lesson_not_found": "Lesson not found",
    "password_error": "Wrong password",
    "invalid_characters": "Invalid characters",
    "enter_session_text": "You are following a link to the session. To access the account, please enter your details",
    "username":  "Name",
    "password_to_enter": "Access password",
    "with_out_registration": "Continue without registration",
    "change_password": "To change the user's password, fill in the details below or use the password recovery procedure on the login form.",
    "current_password": "Current password",
    "errors": {
      "NO_ERROR": "No error found",
      "USER_NOT_FOUND": "User not found",
      "USER_ALREADY_EXISTS": "User already exists",
      "INVALID_PARAMS": "Invalid access params",
      "USER_NOT_ACTIVE": "User not activated",
      "WRONG_PASSWORD": "Wrong password",
      "WRONG_ACTIVATION_CODE": "Wrong activation code",
      "WRONG_SESSION_SIGN": "Wrong session sign",
      "NOT_AUTHENTICATED": "User is not authenticated",
      "WRONG_RESTORE_CODE": "Wrong restore code",
      "USER_ALREADY_ACTIVE": "User already active",
      "WRONG_AUTH_CODE": "Wrong auth code",
      "IMPROPERLY_CONFIGURED": "Wrong config passed",
      "WRONG_PROVIDER": "Wrong social provider",
      "WEAK_PASSWORD": "Password to weak",
      "SOCIAL_AUTH_ERROR": "Error during getting user info",
      "OAUTH_APP_ALREADY_EXISTS": "oauth app already exists",
      "OAUTH_APP_NOT_FOUND": "oauth app not found",
      "OAUTH_CONSUMER_NOT_FOUND": "OAUTH consumer not found",
      "OAUTH_MAX_APPS_REACHED": "Max oauth apps limit reached",
      "OAUTH_CODE_NOT_FOUND": "OAUTH authorization code not found",
      "OAUTH_ACCESS_TOKEN_NOT_FOUND": "OAUTH access token not found",
      "SMTP_SEND_MAIL_ERROR": "SMTP send mail error",
      "WRONG_IMAGE_TYPE": "Wrong image type",
      "SEND_IMAGE_ERROR": "Send image errorr",
      'WRONG_LANGUAGE_TYPE': 'Wrong language type',
      'WRONG_DECODE_DATA': 'Wrong decode data',
      'WRONG_ENCODE_DATA': 'Wrong encode data',
      'WRONG_PROTOBUF_MESSAGE': 'Wrong protobuf message',
      'PERMISSION_DENIED': 'Permission denied',
      'CONTACT_ALREADY_EXISTS': 'Contact already exists',
      'CONTACT_NOT_FOUND': 'Contact not found',
      'CONTACT_NOT_ACTIVE': 'Contact not activated',
    }
  },
  "permissions": {
    "OtherError" : {
      "otherError": "Your camera is unavailable. Please try another device."
    },
    "BlockedAtSystemLevelMacOs": {
      "camera": "Your camera is not connected or {{browserName}} access to the camera is denied at the operating-system level.",
      "check": "Check if the camera works in other applications (like Photo Booth). If everything works, make sure {{browserName}} can access the camera.",
      "p1_menu": "Select the Apple menu",
      "p1_pref": "→ \"System Preferences\", click \"Security & Privacy\", then click \"Privacy\";",
      "p2": "Select Camera and check the box next to the {{browserName}} app;",
      "p3": "Select Microphone and check the box next to the {{browserName}} application;",
      "p4": "Restart {{browserName}} and try the interview again.",
      "more": "Learn more on the",
      "support": "Apple Support site"
    },
    "NoSoundWindowsFirefox": {
      "sureConnected": "If you are sure that your microphone is connected, the most likely issue is that access is blocked in your Windows privacy settings.",
      "goToStart": "Go to Start",
      "parameters": "Settings",
      "privacy": "Privacy",
      "mic": "Microphone. Make sure that:",
      "p1": "Microphone access is enabled for this device;",
      "p2": "\"Allow applications to access microphone\" is set to \"On\";",
      "p3": "\"Allow desktop apps to access my microphone\" (if any) is set to \"On\";",
      "p4": "Refresh the page after making any changes to the settings.",
      "more": "Learn more on the",
      "support": "Microsoft Support site"
    },
    "NotAllowedAndroidChrome": {
      "check": "Failed to access camera and microphone.",
      "p1": "To the left of the site address, click on the lock icon",
      "p2": "Click on the Permissions section;",
      "p3": "Allow access to Camera and Microphone;",
      "p4": "Reload the page.",
      "i1": "If you do not see a Permissions section, then access to the camera and microphone is blocked at the Android operating system level. In Android Settings in the Applications section, make sure that Chrome is allowed to access the Camera and Microphone. After that, go back to Chrome and reload the page. Learn more on the",
      "more": "Android Support site"
    },
    "NotAllowedBlink": {
      "check": "You have not given permission to access your camera or microphone.",
      "p1": "To the left of the site address, click on the lock icon",
      "p2": "Allow access to your camera and microphone;",
      "p3": "Refresh the page",
      "p3_check": "or click here"
    },
    "NotAllowedBlinkWindowsSystem": {
      "check": "Unable to access your microphone and/or camera. Most likely, access to the microphone/camera is denied in the privacy settings of Windows 10.",
      "goToStart": "Go to Start",
      "parameters": "Settings",
      "privacy": "Privacy",
      "cameraAndSure": "Click on Microphone and Camera settings and make sure that your system allows access to both devices.",
      "p1": "Microphone/Camera access is enabled for this device;",
      "p2": "\"Allow apps to access the microphone\" / \"Allow applications to access camera\" is set to \"On\";",
      "p3": "\"Allow desktop apps to access microphone\" / \"Allow desktop apps to access my camera\" (if any) is set to \"On\";",
      "p4": "Refresh the page after making any changes to the settings.",
      "more": "Learn more on the",
      "support": "Microsoft Support site"
    },
    "NotAllowedFirefox": {
      "check": "You have not given permission to access your camera or microphone.",
      "p1": "To the left of the site address, click on the crossed-out camera or microphone icon;",
      "p2": "Remove the ban on camera and microphone access;",
      "p3": "Refresh the page",
      "p4": "Click Allow when Firefox requests access again.",
      "more": "If these recommendations do not help, use",
      "support": "the Firefox support service"
    },
    "NotAllowedMacOsSafari": {
      "check": "You have not given permission to access your camera or microphone.",
      "p1": "Choose the menu tab \"Safari\" → \"Preferences\" and click \"Websites\";",
      "p2": "Select first Camera and then Microphone and set \"Allow\" for huntica.works;",
      "p3": "Close the settings and reload the page.",
      "more": "Learn more on the",
      "support": "Apple Support site"
    },
    "NotConnected": {
      "notConnected": "It looks like your camera is not connected. Please connect your camera."
    },
    "NotReadableErrorAudioWindows": {
      "check": "Unable to access your microphone. Most likely, access to the microphone is denied in the privacy settings of Windows 10.",
      "goToStart": "Go to Start",
      "parameters": "Settings",
      "privacy": "Privacy",
      "mic": "Microphone. Make sure that:",
      "p1": "Microphone access is enabled for this device;",
      "p2": "\"Allow applications to access microphone\" is set to \"On\";",
      "p3": "\"Allow desktop apps to access my microphone\" (if any) is set to \"On\";",
      "p4": "Refresh the page after making any changes to the settings",
      "more": "Learn more on the",
      "support": "Microsoft Support site"
    },
    "NotReadableErrorVideoWindows":{
      "check": "Unable to access your camera. There are two possible reasons:",
      "p1": "Your camera is currently being used by another application (such as Skype or Zoom). Usually, the indicator on the camera will light up. Close all other applications that might be using your camera and then reload the page.",
      "p2": "If you are sure that the camera is not currently in use, then access to it is denied in the privacy settings of Windows 10.",
      "goToStart": "Go to Start",
      "parameters": "Settings",
      "privacy": "Privacy",
      "cameraAndSure": "Camera and make sure that:",
      "a": "Camera access for this device is enabled;",
      "b": "\"Allow apps to access the camera\" is set to \"On\";",
      "c": "\"Allow desktop apps to access camera\" (if any) is set to \"On\";",
      "d": "After making any changes to Settings, refresh the page.",
      "more": "Learn more on the",
      "support": "Microsoft Support site"
    }
  },
  'termsOfService': {
    'header': 'Terms of Service',
    'parts': [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse consectetur ipsum vel lectus dapibus, in pulvinar purus scelerisque. Proin in sodales lectus. Phasellus dapibus nunc ultricies libero dictum accumsan. Vestibulum porta ornare ex, a molestie tortor placerat quis. Nam posuere dolor eu justo viverra laoreet dignissim ac risus. Maecenas nec arcu eu mi eleifend lacinia ac sed ante. Pellentesque vitae iaculis turpis. Nunc vestibulum quam ut magna commodo molestie. Aliquam neque elit, auctor ut libero id, congue iaculis elit. Aliquam nulla massa, consequat eu metus id, auctor posuere neque. Etiam ac turpis blandit, tempus elit sed, commodo arcu. Integer porttitor ligula eget felis euismod bibendum. Etiam vehicula lectus ligula, vel hendrerit diam iaculis at. Ut cursus imperdiet volutpat.',
      'Aliquam varius egestas ante, nec feugiat elit pharetra et. Ut nec enim tellus. Etiam condimentum tortor nec sem commodo iaculis. Cras eget nunc varius nisl rutrum dapibus. Morbi ac erat vitae nulla iaculis fermentum. Ut eget pellentesque ante. Donec rhoncus at odio at viverra. Sed a feugiat nulla. Vivamus imperdiet velit ac sapien condimentum mollis. Etiam vitae sem mi. Maecenas semper sapien ac metus vehicula tincidunt eu vitae sem. Vestibulum commodo, leo vitae ullamcorper pulvinar, elit ligula pharetra ligula, in commodo enim nulla at velit. Vestibulum pellentesque tincidunt libero et luctus.',
      'Duis sed orci malesuada, sagittis eros vel, semper risus. Sed aliquet leo ac sem cursus commodo. Pellentesque ut euismod sem. Cras facilisis ultrices diam, at condimentum magna rutrum eget. Cras sit amet sapien quis quam ullamcorper pulvinar a eget nulla. Sed lorem dui, dapibus sed ex vel, tincidunt consectetur felis. Donec eu vestibulum eros. Quisque quis ullamcorper lectus. Donec vestibulum rhoncus turpis ut cursus. Vestibulum tristique dictum erat, eget pulvinar ligula dictum at. Sed fringilla placerat nisl in vehicula. Donec sit amet semper nisl. Fusce molestie ornare ex, id rhoncus ante semper accumsan. Mauris bibendum, elit non gravida dictum, magna eros aliquet enim, sit amet semper libero dui et neque. Nullam mattis sem accumsan aliquet congue. Nunc id interdum lacus.',
      'Morbi nunc risus, elementum eu porta eu, gravida non nunc. Nunc eu metus ut libero faucibus commodo. Phasellus efficitur, sapien vel sollicitudin auctor, odio turpis finibus tortor, vel vestibulum nulla dui ac orci. Integer varius volutpat mattis. Nulla at dui elit. Nam sit amet augue rutrum, imperdiet quam ut, congue odio. Vestibulum sagittis purus eu arcu sagittis elementum. Vivamus iaculis, odio sit amet convallis elementum, eros tortor lobortis est, nec aliquet leo nibh et arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum sit amet ligula et lectus euismod eleifend eu aliquet libero. Suspendisse scelerisque urna at velit efficitur euismod. Nulla sed velit neque. Vivamus accumsan aliquet rutrum. Quisque in nibh leo. Donec ultricies, felis sed viverra luctus, neque felis volutpat enim, vitae rutrum eros sem in tellus.',
      'Donec a dictum risus, eu lobortis leo. Maecenas commodo accumsan luctus. Pellentesque diam nulla, pulvinar non turpis at, vulputate congue dui. Nulla eu arcu at massa eleifend semper quis sed enim. Sed quis auctor justo. Curabitur iaculis sapien a tellus tempor, et gravida enim tincidunt. In augue magna, aliquam at volutpat et, ultricies in magna.',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse consectetur ipsum vel lectus dapibus, in pulvinar purus scelerisque. Proin in sodales lectus. Phasellus dapibus nunc ultricies libero dictum accumsan. Vestibulum porta ornare ex, a molestie tortor placerat quis. Nam posuere dolor eu justo viverra laoreet dignissim ac risus. Maecenas nec arcu eu mi eleifend lacinia ac sed ante. Pellentesque vitae iaculis turpis. Nunc vestibulum quam ut magna commodo molestie. Aliquam neque elit, auctor ut libero id, congue iaculis elit. Aliquam nulla massa, consequat eu metus id, auctor posuere neque. Etiam ac turpis blandit, tempus elit sed, commodo arcu. Integer porttitor ligula eget felis euismod bibendum. Etiam vehicula lectus ligula, vel hendrerit diam iaculis at. Ut cursus imperdiet volutpat.',
      'Aliquam varius egestas ante, nec feugiat elit pharetra et. Ut nec enim tellus. Etiam condimentum tortor nec sem commodo iaculis. Cras eget nunc varius nisl rutrum dapibus. Morbi ac erat vitae nulla iaculis fermentum. Ut eget pellentesque ante. Donec rhoncus at odio at viverra. Sed a feugiat nulla. Vivamus imperdiet velit ac sapien condimentum mollis. Etiam vitae sem mi. Maecenas semper sapien ac metus vehicula tincidunt eu vitae sem. Vestibulum commodo, leo vitae ullamcorper pulvinar, elit ligula pharetra ligula, in commodo enim nulla at velit. Vestibulum pellentesque tincidunt libero et luctus.',
      'Duis sed orci malesuada, sagittis eros vel, semper risus. Sed aliquet leo ac sem cursus commodo. Pellentesque ut euismod sem. Cras facilisis ultrices diam, at condimentum magna rutrum eget. Cras sit amet sapien quis quam ullamcorper pulvinar a eget nulla. Sed lorem dui, dapibus sed ex vel, tincidunt consectetur felis. Donec eu vestibulum eros. Quisque quis ullamcorper lectus. Donec vestibulum rhoncus turpis ut cursus. Vestibulum tristique dictum erat, eget pulvinar ligula dictum at. Sed fringilla placerat nisl in vehicula. Donec sit amet semper nisl. Fusce molestie ornare ex, id rhoncus ante semper accumsan. Mauris bibendum, elit non gravida dictum, magna eros aliquet enim, sit amet semper libero dui et neque. Nullam mattis sem accumsan aliquet congue. Nunc id interdum lacus.',
      'Morbi nunc risus, elementum eu porta eu, gravida non nunc. Nunc eu metus ut libero faucibus commodo. Phasellus efficitur, sapien vel sollicitudin auctor, odio turpis finibus tortor, vel vestibulum nulla dui ac orci. Integer varius volutpat mattis. Nulla at dui elit. Nam sit amet augue rutrum, imperdiet quam ut, congue odio. Vestibulum sagittis purus eu arcu sagittis elementum. Vivamus iaculis, odio sit amet convallis elementum, eros tortor lobortis est, nec aliquet leo nibh et arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum sit amet ligula et lectus euismod eleifend eu aliquet libero. Suspendisse scelerisque urna at velit efficitur euismod. Nulla sed velit neque. Vivamus accumsan aliquet rutrum. Quisque in nibh leo. Donec ultricies, felis sed viverra luctus, neque felis volutpat enim, vitae rutrum eros sem in tellus.',
      'Donec a dictum risus, eu lobortis leo. Maecenas commodo accumsan luctus. Pellentesque diam nulla, pulvinar non turpis at, vulputate congue dui. Nulla eu arcu at massa eleifend semper quis sed enim. Sed quis auctor justo. Curabitur iaculis sapien a tellus tempor, et gravida enim tincidunt. In augue magna, aliquam at volutpat et, ultricies in magna.',
    ],
    "downloadPDF": "download PDF",
  },
  "supportUs": {
    "headerTitle": "Our goal is to create the best platform for learning to play chess",
    "headerChessClubTitle": "ChessClub Training",
    "headerChessClubSubTitle": "will become better with your help!",
    "donatTitle": "You can donate any amount by choosing a payment method that is convenient for you",
    "donatTitleElements": [
      [
        "https://chessclub.spb.ru/landing/img/sbp.svg",
        "Quick Payment Service",
        "https://chessclub.spb.ru/landing/img/sbpQR.png",
        "",
        [
          "open your bank's app",
          "scan the QR code",
          "enter the amount",
          "confirm the payment"
        ]
      ],
      [
        "https://chessclub.spb.ru/landing/img/wallet.svg",
        "Crypto Wallet",
        "https://chessclub.spb.ru/landing/img/cryptoQR.png",
        "0x53a210adA95b196393137205Ad1D3ED2d9F1A287",
        [
          "Tether (USDT) (ERC20)",
          "Ethereum (ETH)",
          "Solana (SOL)",
          "any others"
        ]
      ]
    ]
  },
  "settings": {
    "personalInfo": "Profile",
    "security": "Security",
    "decoration": "Decoration",
    "userName": "Username",
    "firstName": "Firstname",
    "lastName": "Lastname",
    "dateOfBirth": "Date of birth",
    "town": "Town",
    "email": "Email",
    "sendingMessages": "Receive newsletters about the service",
    "password": "Password",
    'repeat_password': "Repeat password",
    "light": "Light",
    "dark": "Dark",
    "os_theme": "Use system theme",
    "theme": "Theme",
    "active_color": "Active element color",
    "moves_style": "Moving pieces on the board",
    "drag": "Dragging",
    "click": "Mouse clicks",
    "flip": "Flip",
    "rotate": "Rotate",
    "cancel": "Cancel",
  },
  "textEditor": {
    "titles": {
      "text1": "Heading 1",
      "text2": "Heading 2",
      "text3": "Heading 3",
      "text4": "Heading 4",
      "text0": "Normal text",
      "title": "Title"
    },
  },
};
import React from "react";
import {  LargeText } from '../../components';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { ContactsPageBg } from '../Contacts/ContactsPageBg';

export function HelpPage() {
  return <PageTemplate>
    <div style={{ maxWidth: 980, margin: '0 auto' }}>

      <ContactsPageBg/>

      <LargeText text={"help"}/>
      <LargeText text={"under construction"}/>
    </div>
  </PageTemplate>
}
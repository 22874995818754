import React from 'react';
import styled from 'styled-components';
import { Theme, useThemeStore } from '../../../store/themeStore';
import { useStoredState } from '../../../hooks/useStoredState';


export const PlaceholderContainer = styled.div`
  grid-area: C;
  z-index: 1;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
`;

export function ChessboardPlaceholder() {

  const lightTheme = useThemeStore().theme === Theme.light;
  const imageSrc = lightTheme ? '/img/placeholderWhite.png' : '/img/placeholderBlack.png';

  const [ maxWidth ] = useStoredState<number>("board-width", 600);
  const imageWidth = maxWidth - 70;

  return <PlaceholderContainer>
    <img src={imageSrc} alt={'placeholder'} width={imageWidth} />
  </PlaceholderContainer>

}

import { MiniChessboard } from './MiniChessboard';
import React from 'react';
import { useTasksStore } from '../../../../../store/tasks/TasksStore';
import { useConferenceStore } from '../../../../../store/conferenceStore';
import styled from 'styled-components';
import { Button } from '../../../../../components/LowLevelComponents/Button';
import { RawSvg } from '../../../../../components/Icon/RawSvg';
import avatar_placeholder from '../../../../../svg/images/avatar-placeholder.svg'
import avatar_placeholder_white from '../../../../../svg/images/avatar-placeholder-white.svg'
import { ChessboardSvg } from '../../../../../components/Chessboard/ChessboardSvg';
import { FENEnum } from '../../../../../interfaces/question';

const StyledInstructions = styled.div`
  font: ${({ theme }) => theme.fonts.normalBold};
  color: #bebebe;
  margin: 12px;
  width: 100%;
  text-align: center;
`;

const StyledView = styled.div`
  grid-area: C;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;


export function AssignTasksView() {
  const { selected, updateSelection } = useTasksStore();
  const { selectedCameras, setSelectedCameras } = useConferenceStore();
  // const {  } = useLessonStore();

  function cancel (){
    updateSelection(null)
    setSelectedCameras([])
  }

  function assign() {

  }

  return <StyledView >
    <StyledInstructions>
      {getTasksCountText(selected)}
    </StyledInstructions>

    <div style={{ display: 'flex', gap: '1rem', flexWrap:  'wrap-reverse'}}>
      <Tasks/>
    </div>

    <StyledInstructions >
      {getStudentsCountText(selectedCameras)}
    </StyledInstructions>

    <div style={{ gap: 10, fontWeight: 400, display: 'flex', justifyContent: "center" }}>
      <Avatars/>
    </div>

    <div style={{width: "100%", display: 'flex', justifyContent: 'center', padding:10, gap: 10}}>
      <Button onClick={assign}>Assign</Button>
      <Button type={"secondary"} onClick={cancel}>Cancel</Button>
    </div>
  </StyledView>;



}

function Tasks() {
  const { tasks, selected } = useTasksStore();
  return selected.length ? <>
    {tasks.filter(t => selected.includes(t.id)).map((q, i) => (
      <MiniChessboard q={q} key={i} />
    ))}
  </> : <div style={{display: "flex", width: 149}}>
    <ChessboardSvg fen={FENEnum.afterCreation} />
  </div>
}

function Avatars() {
  const { selectedCameras } = useConferenceStore();
  return selectedCameras.length ? <>
    {selectedCameras.map(u => {
      return <img style={{
        width: 100,
        height: 100,
        borderRadius: 50,
        background: '#6662'
      }} src={u.imageUrl} alt='' key={u.id} />;
    })}
  </> : <>
    <RawSvg
      rawSvg={avatar_placeholder}
      rawSvgDark={avatar_placeholder_white}
      width={100}
      height={100}
    />
  </>;
}


function getStudentsCountText(selectedCameras: any[]) {
  return `Выбран${
    selectedCameras.length !== 1 ? 'но' : ''
  } ${
    selectedCameras.length
  } ученик${
    selectedCameras.length === 0 ? 'ов' : selectedCameras.length !== 1 ? 'a' : ''
  }`
}

function getTasksCountText(selected: any[]) {
  return `Выделен${selected.length !== 1 ? 'о' : ''} ${selected.length} вопрос${
    selected.length === 1 ? '' : selected.length > 4 ? 'ов' : 'а'
  }`;
}

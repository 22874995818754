import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { KnownIcons, SvgImage } from '../../components';
import { Theme, useThemeStore } from '../../store/themeStore';

const ImageSliderContainer = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr 80px;
  grid-template-rows: 1fr 45px 45px ;
  max-height: 545px;
  
  & > .images {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    flex-wrap: nowrap;
    gap: 0;
    width: 100%;
    overflow: scroll;
    pointer-events: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
    //border: 1px solid var(--color-text-main);
    box-sizing: border-box;
    & > img {
         min-width: 100%;
      aspect-ratio: 16/9;
      //background-color: #999999;
    }
  }
  
  
  & > .button {
    grid-row: 1;
    background-color: #ffffff40;
    border-radius: 50%;
    margin: auto;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background-color: #ffffff20;
    }
    & > {
      pointer-events: none;
    }
  }
  
  & > .title {
    grid-column: 2;
    grid-row: 2;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    font-size: 18px;
    font-family: Roboto,serif;
    color: var(--color-text-main);
  }
  
  & > .knobs {
    grid-column: 2; 
    grid-row: 3;
    display: flex;
    gap: 30px;
    justify-content: center;
    padding-top: 30px;
    & > div {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #555555;
      &:not(.active) {
        cursor: pointer;
      }
      &.active {
        background-color: #F2994A;
      }
    }
  }

`;


export function ImagesSlider(params: {slides: [string, string, string][]}) {
  const lightTheme = useThemeStore().theme === Theme.light;
  const [index, setIndex] = useState(0);

  useEffect(() => {
    ref.current.scrollLeft = ref.current.scrollWidth/params.slides.length*index;
  }, [index])

  const ref = useRef<HTMLDivElement>();

  function slideLeft() {
    setIndex((111+index - 1)%params.slides.length)
  }

  function slideRight() {
    setIndex((index + 1)%params.slides.length)
  }

  return <ImageSliderContainer>
    <SvgImage className={"button"} icon={KnownIcons.arrow_right} rotate={180} onClick={slideLeft}/>
    <SvgImage className={"button"} icon={KnownIcons.arrow_right} onClick={slideRight}/>
    <div className={"images"} ref={ref}>
      {params.slides.map((slide, i) => {
        return <img src={slide[lightTheme?2:1]} alt={""} key={i} />
      })}
    </div>
    <div className={"title"}>{params.slides[index][0]}</div>
    <div className={"knobs"} >
      {params.slides.map((slide, i) => {
        return <div key={i} className={i === index ? "active" : ""} onClick={() => setIndex(i)}/>
      })}
    </div>
  </ImageSliderContainer>;
}
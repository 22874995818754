import { useLocalization } from '../../l10n/UseLocalization';
import { useLessonStore } from '../../store/lesson/LessonStore';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { ContentContainer } from '../../components/ContentContainer';
import { LessonView } from './LessonView/LessonView';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { LessonPageToolbar } from './Toolbar/LessonPageToolbar';
import { useParams } from 'react-router-dom';
import { RoomId } from '@chessclub/grpc_wrapper';
import { enterLessonRoutine } from '../../store/actions/enterLessonRoutine';
import { LessonContainerSubtitle } from './LessonView/LessonContainerSubtitle';
import { useEditingTaskStore } from '../../store/editing-task/EditingTaskStore';
import { TaskEditView } from '../../parts/TaskEditView/TaskEditView';
import { LessonEditTaskToolbarVariant } from './Toolbar/LessonEditTaskToolbarVariant';
import { EditViewHeader } from '../../parts/EditViewHeader';
import { NotFoundPage } from '../NotFoundPage/NotFoundPage';

export function LessonPage() {

  const { id } = useParams<{ id: RoomId }>();
  const { lesson: lang } = useLocalization();

  const {
    isOnline,
    studentViewSettings,
    isStudentLayout,
    lessonName,
  } = useLessonStore();

  useEffect(() => {
    enterLessonRoutine(id);
  }, [id]);

  if (!lessonName)
    return null; // <NotFoundPage />;


  const showFooter = isStudentLayout ? studentViewSettings?.footer : true;

  return <PageTemplate showFooter={showFooter}>
    <ContentContainer
      title={lang.lesson}
      toolbar={<LessonPageToolbar />}
      subtitle={<LessonContainerSubtitle />}
      subtitleActive={isOnline}
    >
      <LessonPageContentSelector />
    </ContentContainer>
  </PageTemplate>;
}

function LessonPageContentSelector() {

  const { lesson } = useLocalization();

  return useEditingTaskStore().editingTask ?
    <TaskEditView >
      <EditViewHeader text={lesson.edit_task}>
        <LessonEditTaskToolbarVariant/>
      </EditViewHeader>
    </TaskEditView> : <LessonView />;
}
import Centrifuge, { SubscriptionEvents } from 'centrifuge';
import { IQuestion } from '../../interfaces/question';
import {  ChessclubUser, UserRoomRuntimeSettings } from '../../interfaces/user';
import { emitCentrifugeEvent, joinChannelIfAlreadyNot } from '@chessclub/realtime_infrastructure';
import {
  AssignedTasksChangedMessage,
  DemonstrationStateMessage,
  makeRoomChannelKey,
  RoomChannel,
  RoomOnlineStateChanged,
  SetStudentBoardMessage,
  StudentBoardChangedMessage,
  StudentHandUpDownMessage, UserProfileChangedMessage,
} from '../../transport/messaging/RoomChannel';
import { create } from 'zustand';
import { ChannelEvent } from '@chessclub/realtime_infrastructure/src/RealtimeInfrastructure';
import {
  addCentrifugeEventListener as listen,
} from '@chessclub/realtime_infrastructure/src/public/addCentrifugeEventListener';
import { useAuthStore } from '../auth/AuthStore';
import { JoinChannelResult } from '@chessclub/realtime_infrastructure/src/public/joinChannelIfAlreadyNot';
import { IRoom } from '../../interfaces/room';
import { getLocalSocketId } from '../../transport/Socket';
import { ServerApi } from '../../transport/ServerApi';
import { Role, RoomId, TaskId, UserId } from '@chessclub/grpc_wrapper';
import { LessonDemoState, LessonStore, LessonStoreInitial } from './LessonStoreApi';
import { RoomSettings } from '@chessclub/grpc_wrapper/src/api/types/IRoom';
import { useTasksStore } from '../tasks/TasksStore';
import { BoardChannel, makeBoardChannelKey } from '@chessclub/web-game-server';
import { generativeAvatar } from '@chessclub/grpc_wrapper/src/wrappers/utils/generativeAvatar';
import { grpcLang2clientLang } from '@chessclub/grpc_wrapper/src/wrappers/grpc/services/access/converters/grpcLang2clientLang';
import { grpcRole2clientRole } from '@chessclub/grpc_wrapper/src/wrappers/grpc/services/access/converters/grpcRole2clientRole';
import { diff } from '../../logic/diff';
import { wgsStartTask, wgsStopTask } from '../../app/workers';


async function getLesson(roomId: RoomId, userId: UserId) : Promise<IRoom> {
  const room = await ServerApi.roomsService.getRoom(roomId, userId);
  if (typeof room === 'string') return null;
  const profiles = await ServerApi.accessService.getUsersByIds([room.userId]);
  const ownerProfile = profiles.shift();
  return {
    ...room,
    coach: ownerProfile.displayName
  };
}

export const useLessonStore = create<LessonStore>((set, get) => {

  const listeners = [];

  function all<T>(evt: ChannelEvent<T>, callback: (data: T) => void) {
    listeners.push(listen(makeRoomChannelKey(get().roomId), evt, (data) => {
      // console.log(data)
      callback(data)
    }));
  }

  function me<T>(evt: ChannelEvent<T>, callback: (data: T) => void) {
    all(evt, (data: T) => {
      if (data['userId'] === get().currentUserId || data['userIds']?.includes(get().currentUserId)) {
        callback(data);
      }
    });
  }

  async function emit<T>(evt: ChannelEvent<T>, payload: T) {
    const key = makeRoomChannelKey(get().roomId);
    await emitCentrifugeEvent(key, evt, payload);
  }

  async function fillDemonstrationState(demonstrationState : LessonDemoState) {
      if (demonstrationState && !demonstrationState.task) {
        demonstrationState.task = await ServerApi.tasksService.getTaskById(demonstrationState.boardId);
      }
      return demonstrationState;
  }

  async function studentMsgSubscriptions() {
    all(RoomChannel.ROOM_SETTINGS_CHANGED, (data: RoomSettings) => {
      set({studentViewSettings: data})
    })
    all(RoomChannel.ROOM_ONLINE_STATE_CHANGED, (data: RoomOnlineStateChanged) => {
      set({isOnline: data.isOnline, demonstrationState: null})
    });
    all(RoomChannel.DEMONSTRATION_STATE_CHANGE, async (data: DemonstrationStateMessage) => {
      set({ demonstrationState: await fillDemonstrationState(data.demonstration)});
    });
    me(RoomChannel.SET_STUDENT_BOARD, (data: SetStudentBoardMessage) => {
      useTasksStore.getState().setActiveTask(data.questionId)
    });
    me(RoomChannel.REMOVE_SELECTED_STUDENTS, () => {
      useAuthStore.getState().exit();
    });
    me(RoomChannel.STUDENT_HAND_UP_DOWN, (data: StudentHandUpDownMessage) => {
      set({ handIsUp: data.handUp });
    });
    me(RoomChannel.ASSIGNED_TASKS_CHANGED, async (data: AssignedTasksChangedMessage) => {
      const beforeChange = [...useTasksStore.getState().tasks];
      await useTasksStore.getState().loadTasksForUserInRoom({roomId: get().roomId, userId: get().currentUserId})
      const afterChange = [...useTasksStore.getState().tasks];

      const {added, removed} = diff(beforeChange, afterChange);
      added.forEach(task => wgsStartTask(task, get().currentUser.id, get().teacherId))
      removed.forEach(task => wgsStopTask(task.id));
      if (data.changed) {
        wgsStopTask(data.changed)
        wgsStartTask(useTasksStore.getState().tasks.find(t => t.id === data.changed),  get().currentUser.id, get().teacherId)
      }
    });
  }

  async function teacherOnlineMsgSubscriptions() {

    all(RoomChannel.STUDENT_HAND_UP_DOWN, ({ userId, handUp }) => {
      const users = updateUser(userId, user => user.handUp = handUp);
      set({
        users,
        handIsUp: !!users.find(u => u.handUp)
      });
    });

    all(RoomChannel.STUDENT_BOARD_CHANGED, (data: StudentBoardChangedMessage) => {
      const newState: Partial<LessonStoreInitial> = {
        users: updateUser(data.userId, u => u.activeQuestionId = data.questionId)
      };
      if (data.userId === get().monitoringStudentId) {
        newState.monitoringStudentSelectedQuestionId = data.questionId;
      }
      set(newState);
    });

  }

  function updateUser(userId: UserId, f: (user: UserRoomRuntimeSettings) => void) {
    return get().users.map(user => {
      user.id === userId && f(user);
      return {...user};
    });
  }

  async function handlePresenceWhenJoinToRoom(subscription: JoinChannelResult){
    const presence = await subscription.subscription.presence()
    Object.values(presence.clients).forEach(handleClientInfo);
  }

  async function handleClientInfo(entry: Centrifuge.ClientInfo) {
    const user = JSON.parse(entry.user) ;
    const activeQuestion = await ServerApi.tasksService.getActiveTask(get().roomId, user.userId);

    const userData : ChessclubUser = {
      id: user.userId,
      email: user.email,
      imageUrl: null,
      lang: grpcLang2clientLang(user.lang),
      role: grpcRole2clientRole(user.role),
      displayName: user.displayName || user.email,
      session: "n/a",

      socketId: entry.client,
      // cameraEnabled: true,
      handUp: false,
      // microEnabled: true,
      // roomId,
      activeQuestionId: activeQuestion[0]?.id
    };

    userData.imageUrl = user.imageUrl || generativeAvatar(userData);

    get().addUser(userData);
    if (userData.socketId === getLocalSocketId()) {
      get().setCurrentUser(userData);
    }
  }

  async function joinRoom(roomId) {
    const channelKey = makeRoomChannelKey(roomId);
    const subscription = await joinChannelIfAlreadyNot(channelKey);
    const presence = await subscription.subscription.presence()
    const users = Object.keys(presence.clients);
    set({joinUsersSocketIds: users})
    await handlePresenceWhenJoinToRoom(subscription);
    listener('leave', somebodyLeave);
    listener('join', somebodyJoined);

    function listener(type: keyof SubscriptionEvents, fn) {
      subscription.subscription.on(type, fn);
      listeners.push(() => subscription.subscription.off(type, fn));
    }

    async function somebodyJoined(data) {
       // console.log(data)
      await handleClientInfo(data.info)
      // why full reload?
      // subscription.subscription.presence().then(({ presence }) => {
      //   Object.values(presence).forEach((entry) => {
      //     handleClientInfo(entry);
      //   });
      // });
    }

    function somebodyLeave(data) {
      const user = JSON.parse(data.info.user);
      set({ users: get().users.map(u => {
          if (u.id === user.userId) {
              u.socketId = null;
          }
          return u;
        }) });
    }
  }

  return {

    users: [],

    emitProfileChanged(changed:UserProfileChangedMessage) {
      
    },

    async handUpAction() {
      const {isStudentLayout, currentUser, handIsUp} = get();
      if (isStudentLayout) {
        const handUp = !handIsUp;
        set({handIsUp: handUp});
        await emit(RoomChannel.STUDENT_HAND_UP_DOWN, {
            userId: currentUser.id,
            handUp
        });
      } else {

      }
    },

    async toggleStudentMonitoringMode(userId: UserId) {
      const {roomId, teacherId, monitoringStudentId} = get();
      const exit = !userId || monitoringStudentId === userId;

      await useTasksStore.getState().loadTasksForUserInRoom({
        roomId,
        userId: exit ? teacherId : userId
      });

      if (exit) { // exit student monitoring mode
        set({
          monitoringStudentId: null,
          monitoringStudentSelectedQuestionId: null,
          monitoringStudentDisplayingQuestionId: null
        });
      } else { // enter student monitoring mode
        const activeTask = useTasksStore.getState().activeTask?.id;
        set({
          monitoringStudentId: userId,
          monitoringStudentSelectedQuestionId: activeTask,
          monitoringStudentDisplayingQuestionId: activeTask
        });
      }
    },

    async goOnline(roomId: RoomId) {

    },

    async goOffline(roomId: RoomId) {
      if (roomId !== get().roomId)
        return;
      set({
        // currentLesson: null,
        demonstrationState: null,
        monitoringStudentId: null,
        monitoringStudentSelectedQuestionId: null,
        monitoringStudentDisplayingQuestionId: null,
        isOnline: false
      });
    },

    setActiveLesson (room: IRoom) {
      set({
        teacherId: room.userId,
        isOnline: room.isActive,
        studentViewSettings: room.settings
      });
    },

    async exitLesson(roomId: RoomId) {
      const {roomId: lessonRoomId} = get();
      if (roomId !== lessonRoomId)
        return;
      listeners.forEach(remove => remove());
      listeners.splice(0, listeners.length);
      set({isOnline: false });
    },

    async enterLesson( params: {userId: UserId, roomId: RoomId}) {
      const {userId, roomId} = params

      const {roomId: lessonRoomId} = get();

      if (lessonRoomId === roomId) {
        // lesson is already current
        return;
      }

      if (lessonRoomId) { // previous lesson
        // unsubscribe from previous lesson (teacher)
        listeners.forEach(remove => remove());
        listeners.splice(0, listeners.length);
      }

      const lesson = await getLesson(roomId, userId);
      if (lesson === null) return;
      const loggedUserIsRoomOwner = lesson.userId === userId;


      set({

        monitoringStudentId: null,
        monitoringStudentSelectedQuestionId: null,
        monitoringStudentDisplayingQuestionId: null,

        users: await ServerApi.accessService.getUsersByIds(lesson.students),
        roomId,
        currentUserId: userId,
        isOnline: lesson.isActive,
        lessonName: lesson.name,
        teacherId: lesson.userId,
        teacherName: lesson.coach,
        studentViewSettings: lesson.settings,
        demonstrationState: await fillDemonstrationState(lesson.customData.demonstration),
        isStudentLayout: !loggedUserIsRoomOwner
      });

      await joinRoom(roomId);

      if (loggedUserIsRoomOwner) {
        lesson.isActive && await teacherOnlineMsgSubscriptions();
      } else {
        await studentMsgSubscriptions();
      }

    },

    async demonstrateButtonClicked(task: IQuestion) {
      const {demonstrationState, roomId} = get();
      const newDemonstrationState: LessonDemoState = demonstrationState ? null : {
        boardId: task.id,
        shouldShowSteps: false,
        shouldShowTools: false,
        task
      };

      set({demonstrationState: newDemonstrationState});
      const payload : DemonstrationStateMessage = {
        demonstration: newDemonstrationState
      };
      await ServerApi.roomsService.updateRoom({
        id: roomId,
        isActive: true, // если не отправлять true то комната заофлайнится
        customData: payload
      });
      await emit(RoomChannel.DEMONSTRATION_STATE_CHANGE, payload);
    },

    setCurrentMonitoringQuestionItem: (questionId: TaskId | null) => {
      set({ monitoringStudentDisplayingQuestionId: questionId });
    },

    async setStudentHandUp(data: { handUp: boolean; userId: UserId }) {
      set({ users: updateUser(data.userId, user => user.handUp = data.handUp) });
      await emitCentrifugeEvent(makeRoomChannelKey(get().roomId), RoomChannel.STUDENT_HAND_UP_DOWN, {
        userId: data.userId,
        handUp: data.handUp,
      });
    },

    setUsers: (users: LessonStoreInitial['users']) => {
      const { teacherId } = get();

      set({
        users: users.map((user) => {
          if (teacherId && (user.role === Role.TEACHER || user.role === Role.ADMIN) && user.id !== teacherId) {
            // todo ???
            user.role = Role.STUDENT;
            return user;
          }
          return user;
        }),
      });
    },
    addUser: (user: ChessclubUser) => {
      const { teacherId } = get();

      if (teacherId && (user.role === Role.TEACHER || user.role === Role.ADMIN )&& user.id !== teacherId) {
        user.role = Role.STUDENT;
      }

      let found = get().users.find(({ id }) => id === user.id);
      if (found) {
        Object.assign(found, user);
        set({ users: [...get().users] });
      } else {
        set({ users: [...get().users, user] });
      }
    },
    setCurrentUser: (currentUser:  ChessclubUser) => {
      // currentUser.microEnabled = useConferenceStore.getState().getLastAudioState(roomId)
      // currentUser.cameraEnabled =  useConferenceStore.getState().getLastVideoState(roomId)

      set({ currentUser });
    },

    async toggleCanMoveById(userId: UserId) {

      const canMove = useTasksStore.getState().currentTaskBoardState?.canMove.includes(userId);

      await emitCentrifugeEvent(
        makeBoardChannelKey(get().demonstrationState.boardId),
        BoardChannel.USER_CAN_MOVE,
        {
          userId,
          canDoMoves: !canMove
        },
      );

    },

    async setActiveStudentQuestion(taskId: TaskId) {

      await emit(RoomChannel.SET_STUDENT_BOARD, {
        questionId: taskId,
        userId: get().monitoringStudentId,
      });

      set({monitoringStudentSelectedQuestionId: taskId})

    },
  } as LessonStore;

});

import Input from '../../../components/LowLevelComponents/Input';
import React from 'react';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';
import { useLocalization } from '../../../l10n/UseLocalization';

export function TaskEditNameInput() {

  const { updateEditingTaskData, editingTask} = useEditingTaskStore();
  const {lesson} = useLocalization();

  return <Input
    autoFocus
    fullWidth
    label={lesson.name}
    maxLength='256'
    value={editingTask.title}
    onChange={(e) => updateEditingTaskData({ title: e.target.value })}
  />
}
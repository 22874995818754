import React, { CSSProperties, PropsWithChildren } from 'react';
import { NotFoundPageImages } from './NotFoundPageImages';
import { Theme, useThemeStore } from '../../store/themeStore';


function ZeroSizeCenteredContainer(props: PropsWithChildren<any>) {
  return <div style={{
    height: 0,
    width: '100%',
    overflow: 'visible',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none'
  }}>
    {props.children}
  </div>;
}


export function NotFoundPageBg() {

  const lightTheme = useThemeStore().theme === Theme.light;
  const img = lightTheme ? NotFoundPageImages.image404White : NotFoundPageImages.image404Black;

  return <ZeroSizeCenteredContainer>
    <svg width="168" height="257" viewBox="1500 500 168 257"
         overflow="visible" style={{
      zIndex: -2,
      transform: `translate(0, 415px)`
    }}>
      {lightTheme ? <Light/> : <Dark/>}
      <image href={img} x={1220} y={415}/>
    </svg>
  </ZeroSizeCenteredContainer>
}

function Dark() {
  return <>
    <path d="M542 628.048L3168 1257V0.899414L542 628.048Z" fill="#CD9D76" fillOpacity="0.3"/>
    <path d="M542 628.763L0 758.704L1.25889e-05 499.194L542 628.763Z" fill="#CD9D76" fillOpacity="0.3"/>
    <path d="M2626 628.049L0 1256.1L6.09337e-05 0L2626 628.049Z" fill="url(#paint0_radial_8820_128725)" fillOpacity="0.4"/>
    <path d="M2626 628.049L3168 757.804V498.295L2626 628.049Z" fill="url(#paint1_radial_8820_128725)" fillOpacity="0.4"/>
    <defs>
      <radialGradient id="paint0_radial_8820_128725" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1584 618) rotate(90) scale(218 664.957)">
        <stop stopColor="#FFE3B2"/>
        <stop offset="1" stopColor="#363431"/>
      </radialGradient>
      <radialGradient id="paint1_radial_8820_128725" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1584 618) rotate(90) scale(218 664.957)">
        <stop stopColor="#FFE3B2"/>
        <stop offset="1" stopColor="#363431"/>
      </radialGradient>
    </defs>
  </>
}

function Light() {
  return <>
    <path d="M542 628.047L3168 1257V0.898438L542 628.047Z" fill="#CD9D76" fillOpacity="0.3"/>
    <path d="M542 628.762L0 758.703L1.25889e-05 499.193L542 628.762Z" fill="#CD9D76" fillOpacity="0.3"/>
    <path d="M2626 628.049L0 1256.1L6.09337e-05 0L2626 628.049Z" fill="#674F3B" fillOpacity="0.4"/>
    <path d="M2626 628.049L3168 757.804V498.295L2626 628.049Z" fill="#674F3B" fillOpacity="0.4"/>
  </>
}

import React from 'react';

import { Link } from '../LowLevelComponents/Link';
import { RawSvg } from './RawSvg';

import logo_large_light from '../../svg/images/large-logo-vertical.svg';
import logo_large_dark from '../../svg/images/large-logo-vertical-white.svg';

import logo_small_light from '../../svg/images/small-logo-horizontal.svg';
import logo_small_dark from '../../svg/images/small-logo-horizontal-white.svg';

import logo_tiny_light from '../../svg/images/tiny-logo.svg';
import logo_tiny_dark from '../../svg/images/tiny-logo-white.svg';

import { Stack } from '../LowLevelComponents/Stack';

export enum LogoSize {
  none = 'none',
  tiny = 'tiny',
  small = 'small',
  medium = 'medium',
  large = 'large',
}

interface ILogo {
  size: LogoSize;
  active?: boolean;
}

function isNewYearHat() {
  let month = new Date().getMonth();
  let day = new Date().getDate();
  return (month === 0 && day < 15) || (month === 11 && day > 15);
}

function LargeLogo() {
  return <Stack>
    <RawSvg
      width={250}
      rawSvg={logo_large_light}
      rawSvgDark={logo_large_dark}
    />
    {isNewYearHat() && <img src={'/img/hat.png'} alt={'hat'} width={25}
                            style={{ transform: 'translate(-84px,10px)', zIndex: 111, scale: '-1 1' }} />}
  </Stack>;
}

function MediumLogo() {
  return <Stack>
    <RawSvg
      width={150}
      rawSvg={logo_large_light}
      rawSvgDark={logo_large_dark}
    />
    {isNewYearHat() && <img src={'/img/hat.png'} alt={'hat'} width={25}
                            style={{ transform: 'translate(-77px,2px)', zIndex: 111, scale: '-0.6 0.6' }} />}
  </Stack>;
}


function SmallLogo() {
  return <Stack>
    <RawSvg
      width={160}
      rawSvg={logo_small_light}
      rawSvgDark={logo_small_dark}
    />
    {isNewYearHat() && <img src={'/img/hat.png'} alt={'hat'} width={15}
                            style={{ transform: 'translate(5px,4px)', zIndex: 111, scale: '-1 1' }} />}
  </Stack>;
}

function TinyLogo() {
  return <RawSvg
    width={44}
    rawSvg={logo_tiny_light}
    rawSvgDark={logo_tiny_dark}
  />;

}

export function Logo(props: ILogo) {
  const { size } = props;
  if (size === LogoSize.none)
    return null;
  return <Stack>
    <Link to={'/'}>
      {size === LogoSize.tiny && <TinyLogo />}
      {size === LogoSize.small && <SmallLogo />}
      {size === LogoSize.medium && <MediumLogo />}
      {size === LogoSize.large && <LargeLogo />}
    </Link>
  </Stack>;
}

import React, { useRef } from 'react';
import { useModalStore } from '../../../../../../../store/modalStore';
import { useLessonStore } from '../../../../../../../store/lesson/LessonStore';
import { Role } from '@chessclub/grpc_wrapper';
import { ChessclubUser } from '../../../../../../../interfaces/user';
import { Stack } from '../../../../../../../components/LowLevelComponents/Stack';
import { RawSvg } from '../../../../../../../components/Icon/RawSvg';

import small_chessboard_colored from '../../../../../../../svg/images/small-chessboard-colored.svg'
import { ChessboardWithServerLogic } from '../../../../../../../components/Chessboard/ChessboardWithServerLogic';

const popupId = Math.random();

export function TaskCountIndicator(props: {user: ChessclubUser}) {

  const { isStudentLayout, teacherId } = useLessonStore();
  const isStudentAtTeacherView = !isStudentLayout && props.user.id !== teacherId;
  const { openPopup } = useModalStore();
  const numberBtnRef = useRef<HTMLDivElement>();
  const id = props.user.activeQuestionId;

  if (!isStudentAtTeacherView || !id)
    return

  let onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    openPopup(popupId, <ChessboardWithServerLogic
      multiBoardMode={true}
      boardId={props.user.activeQuestionId}
      noButtons={true}
    />, numberBtnRef.current);
  };

  return <Stack ref={numberBtnRef} onClick={onClick}>
      <RawSvg rawSvg={small_chessboard_colored} width={24} height={24}/>
      {/*<span style={{ fontSize: '18px', zIndex:1, fontFamily: "Roboto",*/}
      {/*  display:'flex', justifyContent: 'center', alignItems:"center"}}>*/}
      {/*    {taskCount}*/}
      {/*</span>*/}
    </Stack>;
}
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { AddCard } from '../../components/LowLevelComponents/AddCard';
import { useEditingTaskStore } from '../../store/editing-task/EditingTaskStore';
import { MaterialCard } from './MaterialCard';
import { IMaterial } from '@chessclub/grpc_wrapper/src/api/types/IMaterial';
import { useUiStore } from '../../store/uiStore';

const MaterialCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  height: 70vh;
  gap: 15px;
  padding: 3px;
`;

export function MaterialsList(props: {
  materials: IMaterial[];
  factory: () => IMaterial;
  onDelete?: (mat: IMaterial) => void;
  onClick?: (mat: IMaterial) => void;
  accent?: (mat: IMaterial) => boolean;
}) {

  const { startEditTask } = useEditingTaskStore();

  return <div>
    {/*<Pagination page={page} max={Math.floor(materials.length/pageSize)} select={setPage}/>*/}
    <MaterialCardsContainer>

      {props.factory && <AddCard onClick={() => startEditTask(props.factory())} />}

      {props.materials.map((mat, i) => <MaterialCard
        material={mat}
        key={i}
        onClick={() => props.onClick(mat)}
        onEdit={props.factory ? () => startEditTask(mat) : null}
        onDelete={props.onDelete ? () => props.onDelete(mat) : null}
        accent={props.accent && props.accent(mat)}
      />)}
    </MaterialCardsContainer>

  </div>;
}
import React, { useRef, useState } from 'react';
import { Button } from '../../components/LowLevelComponents/Button';
import { UserSettingsLayout } from '../../layouts/UserSettingsLayout';
import { StyledInputGroupTitle, TwoButtonsWrapper, } from './common';
import DifferenceController from './DifferenceController';
import ProfileForm from './ProfileForm';
import cloneDeep from 'lodash/cloneDeep';
import { PfpEditor } from './PfpEditor';
import { IProfile } from '@chessclub/grpc_wrapper';
import styled from 'styled-components';
import { uploadAvatar } from '../../store/actions/uploadAvatar';
import { useAuthStore } from '../../store/auth/AuthStore';

const StyledAvatarColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding-right: 47px;

  & > * {
    margin-bottom: 28px;
  }
`;

const StyledUserAvatar = styled.img`
  width: 100%;
  height: auto;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.currentTheme.hover};
`;

const StyledFormColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IProps {
  profile: IProfile;

  onChange(): void;
}


export function PersonalInfoTab(props: IProps) {
  let { profile, onChange } = props;
  const { user } = useAuthStore();
  const imgExtractFnRef = useRef<() => string>();
  const [uploading, setUploading] = useState<boolean>(false)

  const hasChanges = () => {
    return true
  };

  const onSave = async () => {
    const newAvatar = imgExtractFnRef.current();
    if (newAvatar) {
      setUploading(true)
      await uploadAvatar(user.id, newAvatar);
    }
    await onChange();
    setUploading(false)
  };

  return (
    <UserSettingsLayout
      left={
          <PfpEditor
            profileImage={user.imageUrl}
            imgExtractFnRef={imgExtractFnRef}
            uploading={uploading}
        />
      }
      center={
        <StyledFormColumn>
          <ProfileForm onChangeCallback={() => {}} profile={profile} />
          <TwoButtonsWrapper>
            <Button fullWidth type='secondary'>
              Отменить
            </Button>
            <Button disabled={!hasChanges()} fullWidth onClick={onSave}>
              Сохранить
            </Button>
          </TwoButtonsWrapper>
        </StyledFormColumn>
      }
    />
  );
}

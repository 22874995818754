import { Editor } from '@tiptap/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
// text editor
import './TextEditor.css';
import { StyledButton, StyledFigures, StyledMiddle, StyledRight } from '../ChessTools/common';

import { EditorColor, EditorHighlight } from '../Icons';
import { useModalStore } from '../../store/modalStore';
import { useUiStore } from '../../store/uiStore';

import { Modal_TextEditorImage } from '../Modal/variants/Modal_TextEditorImage';
import { Modal_TextEditorTable } from '../Modal/variants/Modal_TextEditorTable';
import { Modal_TextEditorLink } from '../Modal/variants/Modal_TextEditorLink';
import { PopupMenu } from '../PopupMenu/PopupMenu';
import { DropdownMenuStyledComponents } from './DropdownMenuStyledComponents';
import { TextEllipsis } from '../LowLevelComponents/EllipsedText';
import { SvgImage } from '../Icon/SvgImage';
import { KnownIcons } from '../Icon/KnownIcons';
import { TextEditorIcons } from './TextEditorIcons';
import { RawSvgString } from '*.svg';
import { useLocalization } from '../../l10n/UseLocalization';


const {
  MenuText,
  StyledLink,
  StyledColorTitle,
  StyledMenuItem,
} = DropdownMenuStyledComponents;

export interface IMenuItemHanlers {
  id: number;
  onClick: () => void;
  className: string;
}

export interface IMenuBarItem {
  id: number;
  icon: RawSvgString;
}

export interface IMenuBarColors {
  colors: { onClick: () => void; text: string; color: string; isActive?: boolean }[];
  highlight: { onClick: () => void; text: string; color: string; isActive?: boolean }[];
}


const StyledMenu = styled.div<{ interfaceClr?: string }>`
  display: flex;
  align-items: center;
  padding-left: 5px;
  flex-wrap: wrap;
  overflow-y: hidden;
  overflow-x: auto;
  gap: 10px;
  padding-right: 40px;
`;

export const StyledMenuBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  outline: none;
  border: none;
  background: none;
  border-radius: 6px;
  padding: 5px;
  width: 24px;
  height: 24px;
  color: ${({ theme }) => `${theme.currentTheme.iconMain}`};
  &:hover {
    background-color: ${({ theme }) => theme.currentTheme.hover};
  }
  &.is-active {
    background-color: ${({ theme }) => theme.currentTheme.hover};
  }
`;

interface IMenuBarProps {
  editor: Editor;
  readFileAsDataURL: (file: File) => Promise<string>;
}

export function MenuBar(props: IMenuBarProps) {
  let { editor, readFileAsDataURL } = props;
  const { openModal } = useModalStore();
  const { interfaceClr } = useUiStore();
  const { textEditor } = useLocalization();

  // const middleRef = useRef<any>(null);
  const itemsRef = useRef<any>(null);

  const setLink = useCallback((url: string) => {
    // cancelled
    if (url === null) {
      return;
    }
    // empty
    if (url === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }
    // update link
    editor?.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);

  const handleOpenLinkModal = () => {
    const previousUrl = editor.getAttributes('link').href;
    openModal(<Modal_TextEditorLink previousUrl={previousUrl} setLink={setLink} />);
  };

  const handleOpenImageModal = () => {
    openModal(<Modal_TextEditorImage setImage={setImage} />);
  };

  const handleOpenTableModal = () => {
    openModal(<Modal_TextEditorTable setTable={setTable} />);
  };

  const setTable = useCallback((rows: number, cols: number) => {
    editor?.chain().focus().insertTable({ rows, cols, withHeaderRow: true }).run();
  }, [editor]);

  const setImage = useCallback((url: string) => {
    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  }, [editor]);

  const menuItemHandlers: IMenuItemHanlers[] = [
    {
      id: 1,
      onClick: () => editor?.chain().focus().toggleBold().run(),
      className: editor?.isActive('bold') ? 'is-active' : '',
    },
    {
      id: 2,
      onClick: () => editor?.chain().focus().toggleItalic().run(),
      className: editor?.isActive('italic') ? 'is-active' : '',
    },
    {
      id: 3,
      onClick: () => editor?.chain().focus().toggleUnderline().run(),
      className: editor?.isActive('underline') ? 'is-active' : '',
    },
    {
      id: 4,
      onClick: () => editor?.chain().focus().toggleStrike().run(),
      className: editor?.isActive('strike') ? 'is-active' : '',
    },
    {
      id: 5,
      onClick: () => editor?.chain().focus().setTextAlign('left').run(),
      className: editor?.isActive({ textAlign: 'left' }) ? 'is-active' : '',
    },
    {
      id: 6,
      onClick: () => editor?.chain().focus().setTextAlign('center').run(),
      className: editor?.isActive({ textAlign: 'center' }) ? 'is-active' : '',
    },
    {
      id: 7,
      onClick: () => editor?.chain().focus().setTextAlign('right').run(),
      className: editor?.isActive({ textAlign: 'right' }) ? 'is-active' : '',
    },
    {
      id: 8,
      onClick: () => editor?.chain().focus().setTextAlign('justify').run(),
      className: editor?.isActive({ textAlign: 'justify' }) ? 'is-active' : '',
    },
    {
      id: 9,
      onClick: () => editor?.chain().focus().toggleBlockquote().run(),
      className: editor?.isActive('blockquote') ? 'is-active' : '',
    },
    { id: 10, onClick: handleOpenLinkModal, className: editor?.isActive('link') ? 'is-active' : '' },
    {
      id: 11,
      onClick: () => editor?.chain().focus().toggleBulletList().run(),
      className: editor?.isActive('bulletList') ? 'is-active' : '',
    },
    {
      id: 12,
      onClick: () => editor?.chain().focus().toggleOrderedList().run(),
      className: editor?.isActive('orderedList') ? 'is-active' : '',
    },
    {
      id: 13,
      onClick: () => editor?.chain().focus().toggleTaskList().run(),
      className: editor?.isActive('taskList') ? 'is-active' : '',
    },
    { id: 14, onClick: handleOpenTableModal, className: editor?.isActive('table') ? 'is-active' : '' },
    { id: 15, onClick: handleOpenImageModal, className: '' },
    {
      id: 16,
      onClick: () => editor?.chain().focus().setParagraph().run(),
      className: editor?.isActive('paragraph') ? 'is-active' : '',
    },
    {
      id: 17,
      onClick: () => {
        editor?.commands.clearNodes();
        editor?.commands.unsetColor();
        editor?.commands.unsetHighlight();
        editor?.chain().focus().setParagraph().run();
      },
      className: '',
    },
    {
      id: 18,
      onClick: () => editor?.chain().focus().undo().run(),
      className: '',
    },
    {
      id: 19,
      onClick: () => editor?.chain().focus().redo().run(),
      className: '',
    },
  ];

  const allMenuBarItems: IMenuBarItem[] = [
    {
      id: 1,
      icon: TextEditorIcons.bold,
    },
    {
      id: 2,
      icon: TextEditorIcons.italic,
    },
    {
      id: 3,
      icon: TextEditorIcons.underline,
    },
    {
      id: 4,
      icon: TextEditorIcons.linethrought,
    },
    {
      id: 5,
      icon: TextEditorIcons.left,
    },
    {
      id: 6,
      icon: TextEditorIcons.center,
    },
    {
      id: 7,
      icon: TextEditorIcons.right,
    },
    {
      id: 8,
      icon: TextEditorIcons.justify,
    },
    {
      id: 9,
      icon: TextEditorIcons.quote,
    },
    {
      id: 10,
      icon: TextEditorIcons.chain,
    },
    {
      id: 11,
      icon: TextEditorIcons.unordList,
    },
    {
      id: 12,
      icon: TextEditorIcons.ordList,
    },
    {
      id: 13,
      icon: TextEditorIcons.taskList,
    },
    {
      id: 14,
      icon: TextEditorIcons.table,
    },
    {
      id: 15,
      icon: TextEditorIcons.ImageIcon,
    },
    {
      id: 16,
      icon: TextEditorIcons.paragraph,
    },
    {
      id: 17,
      icon: TextEditorIcons.clearFormat,
    },
    {
      id: 18,
      icon: TextEditorIcons.undo,
    },
    {
      id: 19,
      icon: TextEditorIcons.redo,
    },
  ];

  const [menuBarItems, setmenuBarItems] = useState(allMenuBarItems);
  const [hiddenMenuBarItems, setHiddenMenuBarItems] = useState([]);

  // const calcShouldDropFig = () => {
  //   if (!itemsRef.current) return;
  //   if (
  //     allMenuBarItems.length === menuBarItems.length &&
  //     itemsRef.current.clientWidth === allMenuBarItems.length * 34 - 10
  //   )
  //     return;
    // const margin = parseFloat(getComputedStyle(middleRef.current).marginRight);
    // const scroll = itemsRef.current.scrollWidth - itemsRef.current.clientWidth;

  //   if (scroll >= 9) {
  //     if (!menuBarItems.length) return;
  //     const hiddenFIgures = [];
  //     hiddenFIgures.push(menuBarItems.pop());
  //     setHiddenMenuBarItems([...hiddenMenuBarItems, ...hiddenFIgures]);
  //     setmenuBarItems([...menuBarItems]);
  //   } else if (margin >= 34) {
  //     if (!allMenuBarItems[menuBarItems.length]) {
  //       return;
  //     }
  //     const newFigure = { ...allMenuBarItems[menuBarItems.length] };
  //     menuBarItems.push(newFigure);
  //     setmenuBarItems([...menuBarItems]);
  //     setHiddenMenuBarItems(hiddenMenuBarItems.filter((figure) => figure.id !== newFigure.id));
  //   }
  // };

  // useEffect(calcShouldDropFig);

  function ChooseColorDropdownMenu() {
    const items: IMenuBarColors = {
      colors: [
        {
          onClick: () => {
            editor?.commands.unsetHighlight();
            editor?.chain().focus().unsetColor().run();
          },
          text: 'Обычный цвет',
          color: null,
          isActive: !editor?.getAttributes('textStyle').color ? true : false,
        },
        {
          onClick: () => {
            editor?.commands.unsetHighlight();
            editor?.chain().focus().setColor('#27AE60').run();
          },
          text: 'Зеленый',
          color: '#27AE60',
          isActive: editor?.isActive('textStyle', { color: '#27AE60' }) ? true : false,
        },
        {
          onClick: () => {
            editor?.commands.unsetHighlight();
            editor?.chain().focus().setColor('red').run();
          },
          text: 'Красный',
          color: 'red',
          isActive: editor?.isActive('textStyle', { color: `red` }) ? true : false,
        },
        {
          onClick: () => {
            editor?.commands.unsetHighlight();
            editor?.chain().focus().setColor('#EF9033').run();
          },
          text: 'Оранжевый',
          color: '#EF9033',
          isActive: editor?.isActive('textStyle', { color: '#EF9033' }) ? true : false,
        },
        {
          onClick: () => {
            editor?.commands.unsetHighlight();
            editor?.chain().focus().setColor('#40A7E3').run();
          },
          text: 'Синий',
          color: '#40A7E3',
          isActive: editor?.isActive('textStyle', { color: '#40A7E3' }) ? true : false,
        },
      ],
      highlight: [
        {
          onClick: () => {
            editor.chain().focus().toggleHighlight({ color: '#27AE60' }).run();
          },
          text: 'Зеленый',
          color: '#27AE60',
          isActive: editor?.isActive('highlight', { color: '#27AE60' }) ? true : false,
        },
        {
          onClick: () => {
            editor.chain().focus().toggleHighlight({ color: 'red' }).run();
          },
          text: 'Красный',
          color: 'red',
          isActive: editor?.isActive('highlight', { color: 'red' }) ? true : false,
        },
        {
          onClick: () => {
            editor.chain().focus().toggleHighlight({ color: '#EF9033' }).run();
          },
          text: 'Оранжевый',
          color: '#EF9033',
          isActive: editor?.isActive('highlight', { color: '#EF9033' }) ? true : false,
        },
        {
          onClick: () => {
            editor.chain().focus().toggleHighlight({ color: '#40A7E3' }).run();
          },
          text: 'Синий',
          color: '#40A7E3',
          isActive: editor?.isActive('highlight', { color: '#40A7E3' }) ? true : false,
        },
      ],
    };
    return <StyledMenu>
      {items &&
        (items as IMenuBarColors).colors.slice(0, 1).map((color, i) => {
          const el: any = color;
          if (el === null) return null;
          else {
            const handleClick = () => {
              if (el.onClick) {
                el.onClick();
                // handleClose && handleClose();
              }
            };
            const menuItem = (
              <StyledMenuItem
                // nohover={noHover}
                // compact={compact}
                currentitem={el.isActive}
                disabled={el.disabled}
                // menuitemcolorkey={menuItemColorKey}
                key={i}
                onClick={el.disabled ? () => {
                } : handleClick}
                // onMouseDown={closeOnMoouseDown && (el.disabled ? () => {} : handleClick)}
                postfix={el.postfix}
                checked={el.checked}
                leftpad={true}
              >
                <EditorColor fill={el.color} />
                {el.text && (
                  <MenuText style={{ width: '100%' }}>
                    <TextEllipsis
                      text={el.text}
                      style={{
                        color: el.color,
                      }}
                    />
                  </MenuText>
                )}
              </StyledMenuItem>
            );
            return el.href && !el.onClick ? (
              <StyledLink key={i} to={el.href}>
                {menuItem}
              </StyledLink>
            ) : (
              menuItem
            );
          }
        })}
      <StyledColorTitle topPadding={true}>ЦВЕТ ТЕКСТА</StyledColorTitle>
      {items &&
        (items as IMenuBarColors).colors.slice(1).map((color, i) => {
          const el: any = color;
          if (el === null)
            return null;
          else {
            const handleClick = () => {
              if (el.onClick) {
                el.onClick();
                // handleClose && handleClose();
              }
            };
            const menuItem = (
              <StyledMenuItem
                // nohover={noHover}
                // compact={compact}
                currentitem={el.isActive}
                disabled={el.disabled}
                // menuitemcolorkey={menuItemColorKey}
                key={i}
                onClick={el.disabled ? () => {
                } : handleClick}
                // onMouseDown={closeOnMoouseDown && (el.disabled ? () => {} : handleClick)}
                postfix={el.postfix}
                checked={el.checked}
                leftpad={true}
              >
                <EditorColor fill={el.color} />
                {el.text && (
                  <MenuText style={{ width: '100%' }}>
                    <TextEllipsis
                      text={el.text}
                      style={{
                        color: el.color,
                      }}
                    />
                  </MenuText>
                )}
              </StyledMenuItem>
            );
            return el.href && !el.onClick ? (
              <StyledLink key={i} to={el.href}>
                {menuItem}
              </StyledLink>
            ) : (
              menuItem
            );
          }
        })}
      <StyledColorTitle topPadding={true}>ЦВЕТ ВЫДЕЛЕНИЯ</StyledColorTitle>
      {items &&
        (items as IMenuBarColors).highlight.map((color, i) => {
          const el: any = color;
          if (el === null) return null;
          else {
            const handleClick = () => {
              if (el.onClick) {
                el.onClick();
                // handleClose && handleClose();
              }
            };
            const menuItem = (
              <StyledMenuItem
                // nohover={noHover}
                // compact={compact}
                currentitem={el.isActive}
                disabled={el.disabled}
                // menuitemcolorkey={menuItemColorKey}
                key={i}
                onClick={el.disabled ? () => {
                } : handleClick}
                // onMouseDown={closeOnMoouseDown && (el.disabled ? () => {} : handleClick)}
                postfix={el.postfix}
                checked={el.checked}
                leftpad={true}
              >
                <EditorHighlight fill={el.color} />
                {el.text && (
                  <MenuText style={{ width: '100%' }}>
                    <TextEllipsis
                      text={el.text}
                      style={{
                        color: el.color,
                      }}
                    />
                  </MenuText>
                )}
              </StyledMenuItem>
            );
            return el.href && !el.onClick ? (
              <StyledLink key={i} to={el.href}>
                {menuItem}
              </StyledLink>
            ) : (
              menuItem
            );
          }
        })}
    </StyledMenu>;
  }

  function ChooseHeadingDropdownMenu() {
    const items = [
      {
        text: textEditor.titles.text1,
        onClick: () => {
          editor?.chain().focus().setHeading({ level: 1 }).run();
        },
        isActive: editor?.isActive('heading', { level: 1 }) ? true : false,
      },
      {
        text: textEditor.titles.text2,
        onClick: () => {
          editor?.chain().focus().setHeading({ level: 2 }).run();
        },
        isActive: editor?.isActive('heading', { level: 2 }) ? true : false,
      },
      {
        text: textEditor.titles.text3,
        onClick: () => {
          editor?.chain().focus().setHeading({ level: 3 }).run();
        },
        isActive: editor?.isActive('heading', { level: 3 }) ? true : false,
      },
      {
        text: textEditor.titles.text4,
        onClick: () => {
          editor?.chain().focus().setHeading({ level: 4 }).run();
        },
        isActive: editor?.isActive('heading', { level: 4 }) ? true : false,
      },
      {
        text: textEditor.titles.text0,
        onClick: () => {
          editor?.chain().focus().setParagraph().run();
        },
        isActive: editor?.getAttributes('heading').level ? false : true,
      },
    ];
    // const { handleClose, items, noHover, compact, closeOnMoouseDown, leftPad, pathname } = props;
    return <StyledMenu>
      {items &&
        items.map((el, i) => {
          if (el === null) return null;
          else {
            const handleClick = () => {
              if (el.onClick) {
                el.onClick();
                // handleClose && handleClose();
              }
            };
            const menuItem = (
              <StyledMenuItem
                // nohover={noHover}
                // compact={compact}
                currentitem={el.isActive}
                // disabled={el.disabled}
                // menuitemcolorkey={props.menuItemColorKey}
                key={i}
                // onClick={el.disabled ? () => {
                // } : handleClick}
                // onMouseDown={closeOnMoouseDown && (el.disabled ? () => {
                // } : handleClick)}
                // postfix={el.postfix}
                // checked={el.checked}
                // leftpad={leftPad}
              >
                {/*{typeof el.icon === 'string' ? (*/}
                {/*  <Icon type={el.icon} status={el.status} size={el.size || 'medium'} />*/}
                {/*) : (*/}
                {/*  el.icon*/}
                {/*)}*/}

                {el.text && (
                  <MenuText style={{ width: '100%' }}>
                    <TextEllipsis
                      text={el.text}
                      style={{
                        padding: '6px 0',
                        fontWeight: `${el.text !== textEditor.titles.text0 && 'bold'}`,
                        fontSize: `${
                          el.text.includes('1')
                            ? '34px'
                            : el.text.includes('2')
                              ? '25px'
                              : el.text.includes('3')
                                ? '20px'
                                : el.text.includes('4')
                                  ? '17px'
                                  : ''
                        }`,
                      }}
                    />
                  </MenuText>
                )}
              </StyledMenuItem>
            );
            return menuItem;
          }
        })}
    </StyledMenu>;
  }

  function HiddenMenuItemsDropdownMenu() {

    return <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', padding: '10px' }}>
      {hiddenMenuBarItems &&
        hiddenMenuBarItems.map((el, i) => {
          if (el === null) return null;
          else {
            const handlers = menuItemHandlers.find((i) => i.id === el.id);
            const handleClick = () => {
              const func = handlers.onClick;
              if (func) func();
            };
            const menuItem = (
              <StyledMenuBtn
                key={el.id}
                onClick={el.disabled ? () => {
                } : handleClick}
                className={handlers.className}
              >
                {typeof el.icon === 'string' ? <Ico type={el.icon} /> : el.icon}
              </StyledMenuBtn>
            );
            return menuItem;
          }
        })}
    </div>;

  }

  if (!editor) {
    return null;
  }

  return (
    <StyledMenu>
      {/*<StyledMiddle ref={middleRef}>*/}
        <StyledFigures ref={itemsRef}>
          <PopupMenu popupContent={ChooseHeadingDropdownMenu}>
            <StyledButton style={{ paddingRight: 0 }}>
          <span className='title' style={{ marginRight: '.5rem' }}>
              <span style={{ whiteSpace: 'nowrap' }}>
              {editor?.getAttributes('heading').level
                ? (textEditor.titles.title + " " + editor?.getAttributes('heading').level)
                : textEditor.titles.text0}
            </span>
          </span>
              <SvgImage rotate={90} className='subject-icon' icon={KnownIcons.arrow_right} />
            </StyledButton>
          </PopupMenu>
          {menuBarItems.slice(0, 8).map((i) => {
            const handler = menuItemHandlers.find((item) => item.id === i.id);
            return (
              <StyledMenuBtn onClick={handler.onClick} key={i.id} className={handler.className}>
                <Ico type={i.icon} />
              </StyledMenuBtn>
            );
          })}

          <PopupMenu popupContent={ChooseColorDropdownMenu}>
            <StyledButton style={{ paddingRight: 0 }}>
              <span
                className='title'
                style={{ marginRight: '.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                {editor?.getAttributes('textStyle').color ? (
                  <EditorColor fill={editor?.getAttributes('textStyle').color} />
                ) : editor?.getAttributes('highlight').color ? (
                  <EditorHighlight fill={editor?.getAttributes('highlight').color} />
                ) : (
                  <EditorColor />
                )}
              </span>
              <SvgImage style={{ transform: 'rotate(90deg)' }} className='subject-icon'
                        icon={KnownIcons.arrow_right} />
            </StyledButton>
          </PopupMenu>
          {menuBarItems.slice(8).map((i) => {
            const handler = menuItemHandlers.find((item) => item.id === i.id);
            return (
              <StyledMenuBtn onClick={handler.onClick} key={i.id} className={handler.className}>
                <Ico type={i.icon} />
              </StyledMenuBtn>
            );
          })}
        </StyledFigures>
        {hiddenMenuBarItems.length > 0 && (
          <StyledRight>
            <PopupMenu popupContent={HiddenMenuItemsDropdownMenu}>
              <SvgImage style={{ transform: 'rotate(90deg)' }} className='subject-icon'
                        icon={KnownIcons.arrow_right} />
            </PopupMenu>
          </StyledRight>
        )}
      {/*</StyledMiddle>*/}
    </StyledMenu>
  );
}

function Ico(props: {type: RawSvgString}) {
  return <SvgImage icon={props.type} size={16} />
}



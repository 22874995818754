import React, { PropsWithChildren } from 'react';
import { KnownIcons } from '../Icon/KnownIcons';
import { SvgImage } from '../Icon/SvgImage';
import { PopupMenu } from './PopupMenu';
import { TextNode } from '../LowLevelComponents/TextNode';
import { useModalStore } from '../../store/modalStore';

export type ConfirmationPopupMenuProps = {
  text: string,
  action?: (e?) => void;
  disabled?: boolean
};

export function ConfirmationPopupMenu(props: PropsWithChildren<ConfirmationPopupMenuProps>) {

  const {closePopup} = useModalStore()

  function confirm() {
    closePopup();
    props.action && props.action()
  }

  function popupContent() {
    return <div style={{
      display: 'flex',
      alignItems: "center",
      opacity: props.disabled ? 0.5:1,
      pointerEvents: props.disabled ? "none" : "unset"
    }}>
      <TextNode style={{padding: "0 15px 0 5px"}}>{props.text}</TextNode>
      {props.action && <SvgImage icon={KnownIcons.check_mark} withHover filled onClick={confirm} />}
      <SvgImage icon={KnownIcons.cross} withHover />
    </div>;
  }

  return <PopupMenu popupContent={popupContent}  disabled={props.disabled}>
    {props.children}
  </PopupMenu>;
}


import React, { FunctionComponent, useState } from 'react';
import {  InputAdornment } from '@material-ui/core';
import { IInput } from '../../interfaces/input';
import Input from './Input';
import { SvgImage } from '../Icon/SvgImage';
import { DiagonalLineIconModifier } from '../Icon/DiagonalLineIconModifier';
import { KnownIcons } from '../Icon/KnownIcons';
import { JustText } from '../JustText/JustText';


const PasswordInput: FunctionComponent<IInput> = (props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleClickShowPassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  return <div>

    {props.showErrorText && <JustText style={{
      alignSelf: 'flex-start',
      marginLeft: '1rem',
      paddingBottom: 5,
      height: 18,
      color: 'var(--color-error1)',
      font: 'var(--font-normal)',

    }}>{
      props.errorText ? props.errorText : '' }&nbsp;</JustText>}

    <Input
      autoComplete={props.autoComplete}
      label={props.label}
      fullWidth={props.fullWidth}
      type={passwordVisible ? 'text' : 'password'}
      value={props.value}
      onChange={(e) => {
        props.onChange(e);
      }}
      error={props.error}
      endAdornment={
        <InputAdornment position="end">
          <div style={{paddingRight:5, height: '100%', display:'flex', alignItems: 'center'}}
               onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
            <DiagonalLineIconModifier color={"red"} isOn={!passwordVisible}>
              <SvgImage icon={KnownIcons.eye} withHover/>
            </DiagonalLineIconModifier>
          </div>
        </InputAdornment>
      }
    />
  </div>;
};

export default PasswordInput;

import { ChessclubUser } from '../../interfaces/user';
import { IProfile } from '@chessclub/grpc_wrapper';

export enum RegisterStep {
  EMAIL = 'EMAIL',
  CAPTCHA = 'CAPTCHA',
  COMPLETE = 'COMPLETE',
}

export enum SocialAuthProvider {
  TELEGRAM = 'telegram',
  GOOGLE = 'google',
  YANDEX = 'yandex',
  VK = 'vk',
  // OK = 'ok',
  // MAIL_RU = 'mailru',
  // FB = 'facebook',
  // TWITTER = 'twitter'
}

export type AuthError = {
  email?: boolean;
  password?: boolean;
  text?: string;
}

export type LessonAuthSettings = {
  password: string;
  error: string;
  captcha: boolean;
}

export interface AuthStoreData {
  user?: ChessclubUser;
  authError?: AuthError;
  inProgress?: AuthError;
  socialAuthProviders: SocialAuthProvider[];
  socketToken: string;
  lessonAuthSettings?: LessonAuthSettings;
}

export interface AuthStoreActions {

  register(): void;

  passwordAuth(email: string, password: string): void;

  lessonAuth(name: string): void;

  socialAuth(provider: SocialAuthProvider): void;

  exit(): void;

  setUser(user: ChessclubUser): void;

  updateProfile(profile: IProfile);

}

export type AuthStore = AuthStoreActions & AuthStoreData;
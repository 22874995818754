export function testUsername(name) {
  let regex = /^(?=.{3,20}$)[a-zA-Z0-9_.-]+$/;

  return regex.exec(name);
}

export function testNameTextFields(name) {
  let regex = /^(?=.{1,20}$)[\p{L}\p{N}_.-]+$/u;

  return regex.test(name);
}

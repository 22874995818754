import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import PasswordInput from '../../components/LowLevelComponents/PasswordInput';
import { ButtonWrapper, InputWrapper, StyledButton } from '../RegisterPage/RegisterSteps';
import { useAuthStore } from '../../store/auth/AuthStore';
import { useStoredState } from '../../hooks/useStoredState';
import { SocialAuth } from './SocialAuth';
import { useLocalization } from '../../l10n/UseLocalization';
import { Space } from '../../components/LowLevelComponents/Space';
import { EmailField } from '../../components/EmailField/EmailField';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { LoginPageContainer } from '../../components/LoginPageContainer/LoginPageContainer';
import { TextLink } from '../../components/PageStyledComponents/TextLink';
import { IAuthFormErrors } from '../../interfaces/auth';


export function LoginPage() {

  const { auth } = useLocalization();
  const [email, setEmail] = useStoredState<string | undefined>('register-email', '');
  const [password, setPassword] = useState<string>('');
  const initialErrors: IAuthFormErrors = { email: false, password: false };
  const [errors, setErrors] = useState(initialErrors);
  const [notValid, setNotValid] = useState<boolean>(false);
  const { authError, passwordAuth } = useAuthStore();
  const [error, setError] = useState('');
  const [errorCount, setErrorCount] = useState(0);

  const [inProgress, setInProgress] = useState<boolean>(false);
  const [isButtonActionDisabled, setButtonActionDisabled] = useState<boolean>(true);

  useEffect(() => {
    let errors: IAuthFormErrors = {};

    errors.email = false;
    errors.password = false;

    setButtonActionDisabled(!email || !password || notValid)
    setError("");
    setErrors(errors);
  }, [email, password, notValid])

  useEffect(() => {
    let errors: IAuthFormErrors = {};

    if(authError?.text) {
      errors.email = true;
      errors.password = true;
      setErrorCount(errorCount + 1);
      console.log(errorCount)
    }
    setError(authError?.text);
    setErrors(errors);

    setButtonActionDisabled(true)
  }, [authError?.text])


  return <PageTemplate header={false}>
    <LoginPageContainer>
      <Space size={40} />
      <SocialAuth />
      <Space size={20}/>
      <Grid container justifyContent='center' direction='column'>
        <InputWrapper>
          <EmailField
            email={email}
            notValid={notValid}
            setNotValid={setNotValid}
            setEmail={(email) => {
              setEmail(email)
            }}
            errorText={error ? auth.errors[error] : ''}
            error={errors.email}
          />
        </InputWrapper>
        <InputWrapper>
          <PasswordInput
            rows={1}
            label={auth.password}
            fullWidth
            value={password}
            onChange={(e) => {setPassword(e.target.value)}}
            error={errors.password}
          />
        </InputWrapper>
        <ButtonWrapper>
          <StyledButton
            fullWidth
            disabled={isButtonActionDisabled || inProgress}
            onClick={async () => {
              setInProgress(true)
              await passwordAuth(email, password)
              setInProgress(false)
            }}>
            {auth.login}
          </StyledButton>
        </ButtonWrapper>
      </Grid>
      <Grid container justifyContent='center' direction='row' style={{ paddingTop: 15 }}>
        <TextLink linkText={auth.forgot_password} to={`/auth/recover/`} />
        <div style={{ margin: '0 15px' }}></div>
        <TextLink linkText={auth.sign_up} to={"/auth/register/"}/>
      </Grid>
    </LoginPageContainer>
  </PageTemplate>;

}

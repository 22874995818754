import React, { useState } from 'react';
import { Agreement, InputWrapper } from './common';
import Input from '../../components/LowLevelComponents/Input';
import Checkbox from '../../components/LowLevelComponents/Checkbox';
import { useAuthStore } from '../../store/auth/AuthStore';
import { IProfile } from '@chessclub/grpc_wrapper';
import styled from 'styled-components';
import { testUsername } from '../../store/auth/testUsername';
import { useLocalization } from '../../l10n/UseLocalization';


const AgreementWrapper = styled.div`
  padding: 0px;
  margin: 30px 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;

  & > *:first-child {
    padding: 0px;
    margin-top: 3px;
    margin-right: 10px;
  }
`;

export interface IProfileForm {
  profile: IProfile;
  onChangeCallback: () => void | null;
}

function ProfileForm(props: IProfileForm) {
  let { profile, onChangeCallback } = props;
  const { user } = useAuthStore();
  const { settings } = useLocalization();

  const [state, setState] = useState(null);
  const [error, serError] = useState(false);

  const onChange = (fieldName, event) => {
    profile[fieldName] = event.target.value || undefined;
    setState(Math.random());
    onChangeCallback && onChangeCallback();
  };

  return (
    <div style={{ width: '540px' }}>

      <InputWrapper>
        <Input
          label={settings.userName}
          value={profile.userName || ''}
          onChange={(e) => {
            if (!testUsername(e.target.value)) {
              serError(true);
            } else {
              serError(false);
            }
            onChange('userName', e);
          }}
          fullWidth
          error={error}
        />
      </InputWrapper>

      <InputWrapper>
        <Input
          label={settings.firstName}
          value={profile.firstName || ''}
          onChange={onChange.bind(null, 'firstName')}
          fullWidth
        />
      </InputWrapper>

      <InputWrapper>
        <Input
          label={settings.lastName}
          value={profile.lastName || ''}
          onChange={onChange.bind(null, 'lastName')}
          fullWidth
        />
      </InputWrapper>

      <InputWrapper>
        <Input
          label={settings.dateOfBirth}
          value={profile.dateOfBirth || ''}
          onChange={onChange.bind(null, 'dateOfBirth')}
          fullWidth
        />
      </InputWrapper>

      <InputWrapper>
        <Input
          label={settings.town}
          value={profile.town || ''}
          onChange={onChange.bind(null, 'town')}
          fullWidth
        />
      </InputWrapper>

      <InputWrapper>
        <Input
          label={settings.email}
          disabled
          fullWidth
          value={user.email}
        />
      </InputWrapper>

      <AgreementWrapper>
        <Checkbox
          onChange={(e) => {
            profile.sendingMessages = e.target.checked;
            onChangeCallback && onChangeCallback();
            setState(Math.random());
          }}
          checked={profile.sendingMessages}
        />
        <Agreement>{settings.sendingMessages}</Agreement>
      </AgreementWrapper>

    </div>
  );
}

export default ProfileForm;

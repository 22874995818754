import React, { FormEvent, PropsWithChildren } from 'react';
import { useTextEditor } from '../../hooks/useTextEditor';
import TextEditor from '../../components/TextEditor/TextEditor';
import Input from '../../components/LowLevelComponents/Input';
import { InputAdornment } from '@material-ui/core';
import { SvgImage } from '../../components/Icon/SvgImage';
import { KnownIcons, randomIcon } from '../../components/Icon/KnownIcons';
import { useEditingTopicStore } from '../../store/editing-topic/EditingTopicStore';
import { IconSelectWidget } from '../../components/IconSelectWidget/IconSelectWidget';
import { useLocalization } from '../../l10n/UseLocalization';

export function TopicEditView(props: PropsWithChildren<{}>) {

  const { editingTopic, updateEditingTopic } = useEditingTopicStore();
  const { lesson, rooms } = useLocalization();

  const { editor, focus, handleBlur, handleFocus, readFileAsDataURL, handlePointerUp } =
    useTextEditor(rooms.description, editingTopic.description, (str) => {
      editingTopic && updateEditingTopic({
        description: str,
      });
    });

  // editor && editor.setEditable(!!editingTopic);

  let endAdornment = editingTopic?.name ? (
    <InputAdornment
      onClick={() => {
        updateEditingTopic({
          name: '',
        });
      }}
      style={{ paddingRight: '2px' }}
      position='start'
    >
      <SvgImage icon={KnownIcons.cross} withHover />
    </InputAdornment>
  ) : null;

  let editName = (e: FormEvent<HTMLInputElement>) => {
    updateEditingTopic({
      name: e.currentTarget.value,
    });
  };
  return <div>
    {props.children}
    <div style={{display: 'flex', gap: 10}}>
      <IconSelectWidget
        icon={editingTopic?.customData?.icon as any || randomIcon()}
        onChange={icon => updateEditingTopic({customData: {icon}})}
      />
      <Input
        autoFocus
        disabled={!editingTopic}
        fullWidth
        label={lesson.name}
        onChange={editName}
        value={editingTopic?.name}
        endAdornment={endAdornment}
      />
    </div>

    <TextEditor
      editor={editor}
      focus={focus}
      handleBlur={handleBlur}
      handleFocus={handleFocus}
      readFileAsDataURL={readFileAsDataURL}
      handlePointerUp={handlePointerUp}
      withMenu={true}
    />

  </div>;
}


import React, { useState } from 'react';
import styled from 'styled-components';
import { ChessboardSvg } from '../../components/Chessboard/ChessboardSvg';
import { IMaterial } from '@chessclub/grpc_wrapper/src/api/types/IMaterial';
import { Stack } from '../../components/LowLevelComponents/Stack';
import { SvgImage } from '../../components/Icon/SvgImage';
import { KnownIcons } from '../../components/Icon/KnownIcons';
import { useMaterialsStore } from '../../store/materials/MaterialsStore';
import { ConfirmationPopupMenu } from '../../components/PopupMenu/ConfirmationPopupMenu';
import { useLessonStore } from '../../store/lesson/LessonStore';
import { useTasksStore } from '../../store/tasks/TasksStore';
import { TaskType } from '@chessclub/grpc_wrapper/src/api/types/QuestionBase';
import { useEditingTaskStore } from '../../store/editing-task/EditingTaskStore';
import { TextNode } from '../../components/LowLevelComponents/TextNode';
import { QuestionBase } from '@chessclub/grpc_wrapper';
import { Card } from '../../components/LowLevelComponents/Card';
import { useLocalization } from '../../l10n/UseLocalization';

const TaskCard = styled(Card)`
  //padding: 5px;
  max-height: 150px;
  box-sizing: border-box;
  cursor: pointer;

  & .board {
    max-width: 150px;
    display: block;
  }

  & .actions {
    opacity: 0;
    transition: 300ms;
    display: flex;
    flex-direction: row-reverse;
    align-items: end;
    padding: 10px 5px;
    box-sizing: border-box;
  }

  &:hover .actions {
    opacity: 1;
  }

  & .title {
    padding-left: 160px;
  }

  //&.edit {
  //  transition: 0.3s;
  //  background: white;
  //  z-index: 100;
  //  transform: scale(4);
  //}
`;

export interface MaterialEntryProps {
  material: QuestionBase;
  onClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  accent?: boolean;
}

export function MaterialCard(props: MaterialEntryProps) {
  const { material, onDelete, accent, onClick, onEdit } = props;
  const [classes] = useState<string>();
  const { lesson } = useLocalization();

  return <TaskCard className={classes} accent={accent} onClick={onClick}>
    <div className='board'>
      <ChessboardSvg fen={material.fen} markers={material.tools&&material.tools[-1]} />
    </div>
    <div className='title'>
      <TextNode>{material.title}</TextNode>
    </div>
    <div className='actions'>
      {onDelete && <ConfirmationPopupMenu text={lesson.delete + "?"} action={onDelete}>
        <SvgImage icon={KnownIcons.trash} withHover />
      </ConfirmationPopupMenu>}
      {onEdit && <SvgImage icon={KnownIcons.pencil} withHover onClick={onEdit} />}
    </div>
  </TaskCard>;
}
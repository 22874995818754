import React, { CSSProperties, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ContentContainerHeader, IHeader } from './ContentContainerHeader';
import { Space } from '../LowLevelComponents/Space';

const StyledContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  min-width: 0;
//  background-color: #8881;
`;

const StyledBody = styled.div`
  padding: 0 1px;
  display: grid;
  min-width: 0;
  //background-color: #8881;
`;

export interface IContentContainer extends IHeader {
  style?: CSSProperties;
}

export function ContentContainer(props: PropsWithChildren<IContentContainer>) {

  const {
    children,
    title = '',
    toolbar,
    subtitle = null,
    subtitleActive = false,
    style,
  } = props;

  return (
    <StyledContainer style={style}>
      {/*<ContentContainerHeader*/}
      {/*  title={title}*/}
      {/*  toolbar={toolbar || <Space size={42}/>}*/}
      {/*  subtitle={subtitle}*/}
      {/*  subtitleActive={subtitleActive}*/}
      {/*/>*/}
      {children}
      {/*<StyledBody>{children}</StyledBody>*/}
    </StyledContainer>
  );
}

import React, { CSSProperties } from 'react';
import { useLocalization } from '../../l10n/UseLocalization';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { NotFoundPageBg } from '../NotFoundPage/NotFoundPageBg';
import { Space } from '../../components/LowLevelComponents/Space';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Link } from '../../components/LowLevelComponents/Link';

function Text(props:{text:string; style?: CSSProperties, className?:string}) {
    return <div {...props} dangerouslySetInnerHTML={{__html: props.text}}/>
}

const Title = styled(Text)`
  font-family: Roboto,serif;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 5px;
  padding-left: 30px;
  color: ${p => p.theme.currentTheme.textMain};
`;

const SubTitle = styled(Text)`
  font-family: Roboto,serif;
  font-size: 21px;
  color: ${p => p.theme.currentTheme.textMain};
`;

const LinkStyled = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.large}px;
  font-family: Roboto,serif;
  font-size: 21px;
  padding-left: 2px;  
  
  a:hover {
    text-decoration: underline;
  }
`;


export function NotFoundPage() {
    const { auth } = useLocalization();

    return <PageTemplate>
        <div style={{ maxWidth: 980, margin: '0 auto' }}>

            <NotFoundPageBg />

            <Space size={460} />

            <Grid container justifyContent='center'>
                <Title text={auth.page_not_found} />
                <Grid container justifyContent='center' direction='row' style={{ paddingTop: 15}}>
                    <SubTitle text={auth.page_not_found_title} />
                    <div style={{ margin: '0 3px' }}></div>
                    <LinkStyled>
                        <Link to={'/'} style={{fontSize: 21, fontFamily: 'var(--font-normal)'}}>{auth.home_page} </Link>
                    </LinkStyled>
                </Grid>
            </Grid>

        </div>
    </PageTemplate>
}

import React, { CSSProperties } from 'react';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { Space } from '../../components/LowLevelComponents/Space';
import { SupportUsPageBg } from './SupportUsPageBg';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { useLocalization } from '../../l10n/UseLocalization';
import { JustText } from '../../components/JustText/JustText';

type CardParams = {
  icon: string;
  title: string;
  image: string;
  link: string;
  text: string[];
};


const CardDonat = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 216px;  
`;

const SectionTitle = styled.div`
  display: flex;
  gap: 20px;
  flex-flow: flex-start;
  justify-content: center;  
  align-items: center;
  min-width: 480px;
  max-width: 480px;
  
  & img {
    width: 40px;
  }
  
  & div {
    font-family: Roboto,serif;
    font-size: 18px;
    text-align: center;
    color: ${p => p.theme.currentTheme.textMain};  
  }
`;

const SectionText = styled.div`
  display: flex;
  gap: 20px;
  flex-flow: flex-start;
  justify-content: center;  
  min-width: 480px;
  max-width: 480px;
  
  & img {
    width: 160px;
  }
  
  & li {
    font-family: Roboto,serif;
    font-size: 15px;
    text-align: left;
    padding-bottom: 10px;
    max-width: 280px;
    color: ${p => p.theme.currentTheme.textMain}; 
  }
`;

const SectionCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;  
  width: 100%;
`;

function Card(props: CardParams) {
  return <div>
    <SectionTitle >
      <img src={props.icon} alt="image"/>
      <Text text={props.title}/>
    </SectionTitle>
    <SectionText {...props}>
      <img src={props.image} alt="image"/>
      <Grid container justifyContent={'center'} direction={'column'}>
        {props.text.map((txt, i) => {
          return <li style={{listStyleType: "disc"}} key={i}>{txt}</li>;
        })}
      </Grid>
    </SectionText>
    <Space size={10}/>
    <JustText style={{userSelect:'text'}}>{props.link}</JustText>
  </div>
}


function CardList(props: {
  elements: [string, string, string, string, string[]][];
  card: React.ComponentType<CardParams>,
  style?: CSSProperties
}) {
  return  <SectionCard style={props.style}>
      {props.elements.map(([ico, title, img, link, text], i) => {
        return <props.card key={i} icon={ico} title={title} image={img} text={text} link={link}/>;
      })}
    </SectionCard>
}


function Text(props:{text:string; style?: CSSProperties, className?:string}) {
  return <div {...props} dangerouslySetInnerHTML={{__html: props.text}}/>
}

const Title = styled(Text)` 
  font-family: Roboto,serif;
  font-size: 28px;
  align-items: center;
  color: ${p => p.theme.currentTheme.textMain};
`;

const SubTitle = styled(Text)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: Roboto,serif;
  align-items: center;
  font-size: 21px;
  color: ${p => p.theme.currentTheme.textMain};
`;

export function SupportUs() {
  const { supportUs } = useLocalization();

  return <PageTemplate>

    <div style={{ maxWidth: 980, margin: '0 auto' }}>

      <SupportUsPageBg/>

      <Grid container justifyContent='center' direction='column' >
        <Space size={30} />
        <SubTitle text={supportUs.headerTitle} />
        <Space size={10} />
        <Grid container alignItems={'center'} justifyContent='center' direction='row'>
          <Title text={supportUs.headerChessClubTitle} />
          <div style={{ margin: '0 3px' }}></div>
          <SubTitle text={supportUs.headerChessClubSubTitle} />
        </Grid>
        <Space size={280} />
        <SubTitle style={{fontWeight: "bold"}} text={supportUs.donatTitle} />
        <Space size={20} />
        <CardList elements={supportUs.donatTitleElements} card={CardDonat} />
      </Grid>

    </div>
  </PageTemplate>;
}

import React from 'react';
import styled from 'styled-components';
import { RawSvgString } from '*.svg';
import { RawSvg } from '../../components/Icon/RawSvg';
import { Link } from '../../components/LowLevelComponents/Link';
import { TextNode } from '../../components/LowLevelComponents/TextNode';


const Container = styled.div`
  box-shadow: 0 0 2px var(--color-icon-main);
  box-sizing: border-box;
  padding: 10px 20px 10px 10px;
`;

export function AdminDashboardCard(props: { icon: RawSvgString; text: string; to: string }) {
  return <Link to={props.to}>
    <Container>
      <RawSvg width={80} height={80} rawSvg={props.icon} />
      <TextNode
        style={{ fontSize: 30,
          justifyContent: 'end', alignItems: 'end' }}>{props.text}</TextNode>
    </Container>
  </Link>;
}
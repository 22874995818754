import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { SvgImage } from '../Icon/SvgImage';

const StyledSocialLogin = styled(SvgImage)`
  width: 40px;
  height: 40px;
  opacity: 0.8;
  transition: 0.2s opacity;

  :hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const StyledSocialsContainer = styled(Grid).attrs({
  container: true,
})`
  align-items: center;
  width: 333px !important;
  justify-content: center;
  margin: 0 auto;
  height: 40px;

  img {
    margin-right: 55px;
    filter: grayscale(50%);
  }
  img:hover {
    filter: grayscale(0%);
  }

  img:last-child {
    margin-right: 0;
  }
`;


export const StyledSocialComponents = {
  StyledSocialsContainer, StyledSocialLogin
}
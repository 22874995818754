import React from 'react';
import { Theme, useThemeStore } from '../../store/themeStore';
import { Theme_dark } from './icons/Theme_dark';
import { Theme_light } from './icons/Theme_light';

export function ThemeSwitch() {
  const { setTheme, theme } = useThemeStore();
  const isLight = theme === Theme.light;
  return <div style={{width: "24px", height: "24px" }} onClick={(e) => {
    setTheme(isLight ? Theme.dark : Theme.light, {x:e.clientX, y:e.clientY});
  }}>
    {isLight ? <Theme_light /> : <Theme_dark />}
  </div>;
}
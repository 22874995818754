import { SocialAuthIcons } from './SocialAuthIcons';
import React, { useEffect, useRef } from 'react';
import { StyledSocialComponents } from '../../components/PageStyledComponents/StyledSocialComponents';
import styled from 'styled-components';
import { Stack } from '../../components/LowLevelComponents/Stack';

const { StyledSocialLogin } = StyledSocialComponents;

const StyledStack = styled(Stack)`
  & iframe {
    width: 40px !important;
    height: 40px !important;
  }
`;

export function TelegramButton() {
  const ref = useRef<HTMLDivElement>();
  useEffect(() => {
    installWidget(ref.current);
  }, []);

  return <StyledStack>
    <StyledSocialLogin icon={SocialAuthIcons.telegram} />
    <div ref={ref}  style={{ opacity:0, display: 'flex', overflow: 'hidden', cursor: 'pointer' }}/>
  </StyledStack>;
}

function createTelegramScript({ botUsername, lang, widgetVersion }) {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://telegram.org/js/telegram-widget.js?${widgetVersion}`;
  script.setAttribute('data-telegram-login', botUsername);
  script.setAttribute('data-size', 'large');
  // script.setAttribute('data-radius', `${cornerRadius}`);
  script.setAttribute('data-request-access', 'write');
  script.setAttribute('data-userpic', 'false');
  script.setAttribute('data-lang', lang );
  script.setAttribute('data-onauth', 'TelegramAuthCallback(user)');

  document.body.append(script);
}

createTelegramScript({
  botUsername: 'chess_club_bot',
  lang: "en",
  widgetVersion: 22
});


async function installWidget(parent) {
  const loginWidget = await querySelectorAsync<HTMLIFrameElement>('iframe#telegram-login-chess_club_bot');
  parent.append(loginWidget);
  loginWidget.style.cursor = "pointer"
  loginWidget.setAttribute("width", "40")
  loginWidget.setAttribute("height", "40")
}

async function querySelectorAsync<T>(selectors, interval = 99): Promise<T> {
  return new Promise(resolve => {
    const intervalId = setInterval(() => {
      const el = document.querySelector(selectors);
      if (!el)
        return;
      clearInterval(intervalId);
      resolve(el)
    }, interval);
  })
}
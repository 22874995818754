import styled from 'styled-components';
import React from 'react';
import { AdminDashboardSubpage } from '../AdminDashboardSubpage';
import { randomIcon } from '../../../components/Icon/KnownIcons';
import { HidingScrollbar } from '../../../components/LowLevelComponents/HidingScrollbar';
import { useAdminKnowledgesStore } from '../../../store/admin/AdminKnowledgesStore';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';
import { useEditingTopicStore } from '../../../store/editing-topic/EditingTopicStore';
import { TaskEditView } from '../../../parts/TaskEditView/TaskEditView';
import { TopicEditView } from '../../../parts/TopicEditView/TopicEditView';
import { randomFen } from '../../../logic/randomFen';
import { TopicType } from '@chessclub/grpc_wrapper/src/api/types/ITopic';
import { TopicEntry } from '../../../parts/MaterialsEditView/TopicEntry';
import { MaterialsList } from '../../../parts/MaterialsEditView/MaterialsList';
import { AddTopicElement } from '../../../parts/MaterialsEditView/AddTopicElement';
import { KnowledgeToolbar } from './toolbar/KnowledgeToolbar';
import { KnowledgeEditingTaskToolbar } from './toolbar/KnowledgeEditingTaskToolbar';
import { KnowledgeEditingTopicToolbar } from './toolbar/KnowledgeEditingTopicToolbar';
import { EditViewHeader } from '../../../parts/EditViewHeader';

const Layout = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-rows: 1fr;
`;

export function AdminDashboardSubpageKnowledge() {
  return <AdminDashboardSubpage
    subtitle={'База знаний'}
    toolbar={<KnowledgeToolbar />}
  >
    <KnowledgePageContent />
  </AdminDashboardSubpage>;
}

function KnowledgePageContent() {
  const { editingTask } = useEditingTaskStore();
  if (editingTask)
    return <TaskEditView>
      <EditViewHeader text={"Edit knowledge task"}>
        <KnowledgeEditingTaskToolbar/>
      </EditViewHeader>
    </TaskEditView>;

  return <Layout>
    <KnowledgeTopics />
    <CenterComponent />
  </Layout>;
}

function CenterComponent() {
  const { editingTopic } = useEditingTopicStore();

  if (editingTopic)
    return <TopicEditView>
      <KnowledgeEditingTopicToolbar/>
    </TopicEditView>;

  return <Knowledges />;
}

function Knowledges() {
  const {knowledges, deleteKnowledge} = useAdminKnowledgesStore();
  return <MaterialsList
    materials={knowledges}
    factory={newKnowledge}
    onDelete={deleteKnowledge}
  />;
}

function KnowledgeTopics() {
  const { topics, selectTopic, selectedTopic, deleteTopic } = useAdminKnowledgesStore();
  return <HidingScrollbar>
    <AddTopicElement topicFactory={newKnowledgeTopic}/>
    {topics.map((topic, i) => <TopicEntry
      topic={topic}
      selected={selectedTopic?.id === topic.id}
      key={i}
      onClick={() => selectTopic(topic)}
      onDelete={() => deleteTopic(topic)}
    />)}
  </HidingScrollbar>;
}

function newKnowledge() {
  return {
    title: 'new knowledge',
    fen: randomFen(),
  };
}

function newKnowledgeTopic() {
  return {
    name: 'new topic',
    type: TopicType.KNOWLEDGE,
    customData: {
      icon: randomIcon(),
    },
  }
}
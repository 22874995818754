import styled from 'styled-components';

const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  * {
    color: ${({ theme }) => theme.currentTheme.iconMain};
    font: ${({ theme }) => theme.fonts.normal};
    font-size: 15px; 
  }
`;

const StyledLinks = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  & svg {
    margin-right: ${({ theme }) => theme.spacing.small}px;
  }
  
  & svg:hover {
    cursor: pointer;
  }

  @media (max-width: 911.33px) {
   :nth-child(1) {
     order: 1;
   }
   :nth-child(2) {
     margin: auto;
     order: 3;
   }
   :nth-child(3) {
     order: 2;
   }
  }

  //@media (max-width: 31.938rem) {
  //  :nth-child(3) {
  //    flex-direction: column;
  //    align-items: flex-start;
  //
  //    .vertical-bar {
  //      display: none;
  //    }
  //  }
  //}

`;


const StyledFooter = styled.footer`
  position: static;
  padding-bottom: 10px;
  width: 100% !important;
  max-width: none !important;
  align-self: flex-end;
`;


export const PageFooterStyledComponents = {
  StyledFooter,
  StyledLinks,
  StyledRow,
}
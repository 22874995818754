import React, { useRef } from 'react';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import styled from 'styled-components';
import { useLocalization } from '../../l10n/UseLocalization';
import { HidingScrollbar } from '../../components/LowLevelComponents/HidingScrollbar';
import { TermsOfServicePageBg } from './TermsOfServisePageBg';
import { Theme, useThemeStore } from '../../store/themeStore';
import { KnownIcons, SvgImage } from '../../components';

const StyledTextPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  font-family: Roboto, sans-serif;
  font-size: 17px;  
  
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.textMain};

  max-width: 1000px;
  height: 100%;
  border-radius: 8px;
  
  @media (max-width: 1000px) {
    width: 98%;
  }  
`;

const StyledHeader = styled.div`
  font-weight: bold;
  max-width: 100%;
  padding: 10px;
`;

const StyledTextBlock = styled.div`
  margin: 10px auto;
  max-width: 100%;
  
  overflow-wrap: break-word;
  word-wrap: break-word; 
  white-space: normal; 
  padding: 10px;
`;


const StyledGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 32px;
  align-items: center;
  gap: 16px;
`;


const StyledDownloadLink = styled.a`
  display: flex;
  padding-right: 10px;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  color: ${({ theme }) => theme.currentTheme.iconMain};
  font: ${({ theme }) => theme.fonts.normal};
  
  &:hover {
    background-color: ${({ theme }) => theme.currentTheme.hover};
  }
`;

const downloadPDF = 'https://chessclub.spb.ru/static/img/TermsOfService.pdf';

export function TermsOfService() {

  const scrollRef = useRef<HTMLDivElement>();
  const { termsOfService } = useLocalization();
  const lightTheme = useThemeStore().theme === Theme.light;
  const backColor = lightTheme ? "rgba(255, 255, 255, 0.8)" : "rgba(44, 44, 44, 0.8)"

  return <PageTemplate>

    <TermsOfServicePageBg />

    <div style={{display:'flex', justifyContent: "center", maxHeight: "calc(100vh - 165px)"}}>

      <StyledTextPage style={{background: backColor}}>
        <StyledGridContainer>
          <StyledHeader>{termsOfService.header}</StyledHeader>
          <StyledDownloadLink href={downloadPDF}>
            <SvgImage icon={KnownIcons.download}/>
            {termsOfService.downloadPDF}
          </StyledDownloadLink>
        </StyledGridContainer>
        <HidingScrollbar
          elementRef={scrollRef}
          style={{height: '100%' }}
          iconStyle={{
            bottom: '3px',
            transform: 'rotate(90deg)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {termsOfService.parts.map((text, i) => {
            return <StyledTextBlock key={i}>{text}</StyledTextBlock>
          })}
        </HidingScrollbar>
      </StyledTextPage>
    </div>
  </PageTemplate>;
}
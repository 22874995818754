import React from 'react';
import { FEN } from '@chessclub/grpc_wrapper';
import { PgnRecordWidget } from './PgnRecordWidget';
import styled from 'styled-components';
import { HidingScrollbar } from '../LowLevelComponents/HidingScrollbar';
import { FigureType } from '../ChessTools/figures-icons/FigureType';
import { FullMoveDescription } from '@chessclub/web-game-server/src/shared/core/calcMoves';
import { Space } from '../LowLevelComponents/Space';

const OneRec = styled.div`
  display: inline-block;
  cursor: pointer;
  padding: 3px;
  color: ${p => p.theme.currentTheme.iconMain};
  &:hover {
    background: ${p => p.theme.currentTheme.hover};
  }
  border-radius: 5px;
  & span {
    font-family: Roboto,serif;
    font-size: 18px;
    vertical-align: middle;
  };
`

const StyledMovesChainWrapper = styled.div`
  width: 100%;
  height: 100%;
  
`;

export function MovesChain(props: { moves?: FullMoveDescription[] }) {
  const { moves } = props;
  if (!moves)
    return null
  let pairs: FullMoveDescription[][] = [];
  if (moves) {
    for (let i = 0; i < moves.length; i += 2) {
      pairs.push(moves.slice(i, i + 2));
    }
  }

  return (
        <StyledMovesChainWrapper>
          {moves.map((rec, i) => {
            return <OneRec key={i} style={{height: 22}}>
              <PgnRecordWidget {...rec} i={i}/>
            </OneRec>
          })}
        </StyledMovesChainWrapper>
  );
}



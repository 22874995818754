import React from 'react';
import styled from 'styled-components';

import {ChatMessageHeader} from './ChatMessageHeader';
import { ChatMessageBody } from './ChatMessageBody';
import { IChatMessage } from '../../../../../../interfaces/chat-message';
import { getDayAndMonth } from '../../../../../../helpers/time.helpers';
import { UserId } from '@chessclub/grpc_wrapper';

const StyledMessageContainer = styled.div<{oneUser?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: ${({ oneUser }) => (oneUser ? '5px' : '10px')};

  p {
    padding: 0;
    margin: 0;
  }

  img {
    width: 30px;
    height: 30px;
    vertical-align: text-bottom;
  }
`;

const StyledMessageDate = styled.span`
  font: ${({ theme }) => theme.fonts.small};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  padding-top: 10px;
  color: ${({ theme }) => theme.currentTheme.iconMain};

  span {
    background-color: ${({ theme }) => theme.currentTheme.hover};
    padding: 3px 10px;
    border-radius: 11px;
  }
`;

export function ChatMessageView(props: IChatMessage & { showMenu: Function, authUserId: UserId, hideHeader:boolean }) {
  const {
    text = '',
    showMenu,
    epoch,
    authUserId,
    author,
    relate,
    withDate,
    hideHeader
  } = props;
  let isMe = author === authUserId
  return (<>
    {withDate && (
      <StyledMessageDate>
        <span>{getDayAndMonth(new Date(epoch * 1000))}</span>
      </StyledMessageDate>
    )}
    <StyledMessageContainer oneUser={hideHeader} onContextMenu={(e) => showMenu(e, props)}>
      {(!isMe && !hideHeader) && <ChatMessageHeader author={author}/>}
      <ChatMessageBody relate={relate}  me={isMe} text={text} time={epoch} />
    </StyledMessageContainer>
  </>);
}


import React from 'react';
import styled from 'styled-components';
import { LessonSideBarTabButton } from './LessonSideBarTabButton';
import { LessonSideBarProps } from './LessonSideBarProps';
import { Stack } from '../../../../../components/LowLevelComponents/Stack';
import { DndType, useDndStore } from '../../../../../store/dndStore';
import { DropZone } from '../../../../../components/LowLevelComponents/DropZone';
import { useLessonUiStore } from '../../../../../store/lesson-ui/LessonUiStoreImpl';
import { LayoutSlots } from '../../../../../store/lesson-ui/LessonUiStoreApi';

const BarHidden = styled.div`
  opacity: 1;
  //height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 32px;
  min-width: 32px;
`;

export function LessonSideBarHiddenMode({ tabs, slot }: LessonSideBarProps) {
  const { changeLayout } = useLessonUiStore();
  const { endDrag } = useDndStore();

  const reverse = slot === LayoutSlots.TOP_RIGHT || slot === LayoutSlots.BOTTOM_RIGHT;

  function onDrop(tab) {
    endDrag();
    changeLayout(slot, tab);
  }

  return <div>
    <Stack>
      <DropZone
        type={DndType.LESSON_TAB}
        onDrop={onDrop}
      />
      <BarHidden style={{ flexDirection: reverse ? 'column-reverse' : 'column' }}>
        {tabs.map(tab => <LessonSideBarTabButton key={tab.type} {...tab} />)}
      </BarHidden>
    </Stack>
  </div>;
}
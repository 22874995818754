import plus from '../../svg/icons/add.svg';
import power_button from '../../svg/icons/on-off.svg';
import gear from '../../svg/icons/settings.svg';
import arrow_up_in_rect from '../../svg/icons/demo-outline.svg';
import chessboard from '../../svg/icons/view-lessons.svg';
import four_chessboards from '../../svg/icons/all-chessboards.svg';
import four_cams from '../../svg/icons/all-camera.svg';
import cross from '../../svg/icons/cancel.svg';
import check_mark from '../../svg/icons/save.svg';
import pencil from '../../svg/icons/edit.svg';
import trash from '../../svg/icons/delete.svg';
import copy from '../../svg/icons/copy.svg';
import paste from '../../svg/icons/paste.svg';
import question_in_rect from '../../svg/icons/questions.svg';
import question from '../../svg/icons/question.svg';
import stopwatch from '../../svg/icons/timer.svg';
import chat from '../../svg/icons/chat.svg';
import camera from '../../svg/icons/cam.svg';
import folder from '../../svg/icons/folder.svg';
import reverse from '../../svg/icons/reverse.svg';
import game_controller from '../../svg/icons/game-controller.svg';
import two_and_half_lines from '../../svg/icons/two-and-half-lines.svg';
import arrow_right from '../../svg/icons/arrow_right.svg';
import magnifying_glass from '../../svg/icons/find.svg';
import flip_vertical from '../../svg/icons/flip-vertical.svg';
import triple_dot_horizontal from '../../svg/icons/menu.svg';
import arrow_left from '../../svg/icons/back.svg';
import attach from '../../svg/icons/attach.svg';
import pawn from '../../svg/icons/figure.svg';
import pawn_with_arrows from '../../svg/icons/figure-move.svg';
import checkmark_in_circle from '../../svg/icons/checkmark-gray.svg';
import human from '../../svg/icons/human.svg';
import lock from '../../svg/icons/lock.svg';
import hand from '../../svg/icons/hand-transparent.svg';
import camera_filled from '../../svg/icons/cam-gray-filled.svg';
import micro_filled from '../../svg/icons/micro.svg';
import circle from '../../svg/icons/circle.svg';
import x8 from '../../svg/icons/dimension-8x.svg';
import x10 from '../../svg/icons/dimension-10x.svg';
import x16 from '../../svg/icons/dimension-16x.svg';
import arrow from '../../svg/icons/arrow.svg';
import pacman from '../../svg/icons/game1.svg';
import eye from '../../svg/icons/eye-enable.svg';
import planet from '../../svg/icons/planet.svg';
import exit from '../../svg/icons/exit.svg';
import ing_yang from '../../svg/icons/ing-yang.svg';
import rotate from '../../svg/icons/rotate.svg';
import rulemove from '../../svg/icons/rulemove.svg';
import tolight from '../../svg/icons/tolight.svg';
import play from '../../svg/icons/play.svg';
import my_cam from '../../svg/icons/my-cam.svg';
import copy_settings from '../../svg/icons/copy-settings.svg';
import diskette from '../../svg/icons/save.svg';
import download from '../../svg/icons/download.svg';

import { pick } from '@chessclub/web-game-server/src/shared/util/math';

export const KnownIcons = {
  plus,
  power_button,
  gear,
  arrow_up_in_rect,
  chessboard,
  four_chessboards,
  four_cams,
  cross,
  check_mark,
  pencil,
  trash,
  copy,
  paste,
  question_in_rect,
  question,
  stopwatch,
  chat,
  camera,
  folder,
  reverse,
  game_controller,
  two_and_half_lines,
  arrow_right,
  magnifying_glass,
  triple_dot_horizontal,
  arrow_left,
  attach,
  pawn,
  pawn_with_arrows,
  checkmark_in_circle,
  human,
  lock,
  hand,
  camera_filled,
  micro_filled,
  circle,
  x8,
  x10,
  x16,
  arrow,
  pacman,
  eye,
  planet,
  exit,
  ing_yang,
  rotate,
  rulemove,
  lamp: tolight,
  play,
  flip_vertical,
  my_cam,
  copy_settings,
  diskette,
  download,
};

export function randomIcon() {
  return pick(Object.keys(KnownIcons))
}
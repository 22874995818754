import { centrifugeInstance, connectToCentrifuge, joinChannelIfAlreadyNot } from '@chessclub/realtime_infrastructure';
import { makeRoomChannelKey } from './messaging/RoomChannel';
import { chessClubConfig } from '../config';



export const { socket } = centrifugeInstance;

export async function joinRoom(roomId: string) {
  console.log('join room', roomId);
  const { subscription } = await joinChannelIfAlreadyNot(
    makeRoomChannelKey(roomId)
  );


  return subscription;

}

export const connectSocket = (token: string, customHeader) => {
  connectToCentrifuge(chessClubConfig.centrifugeUrl + '?hash_pass=' + customHeader, token );
};

export function getLocalSocketId() {
  return centrifugeInstance.socketId;
}

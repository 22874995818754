import React, { useEffect, useRef } from 'react';
import { RedCameraCardButton } from './RedCameraCardButton';
import { PeerConnection } from '@chessclub/video-conf/dist/lib/rtc/PeerConnection';
import { KnownIcons } from '../../../../../../../components';


export function MicButton({showDisabledMedia, microEnabled, localMicroEnabled,  toggleMicroEnabled, pc}: {
  pc?: PeerConnection
  showDisabledMedia: boolean;
  microEnabled: boolean;
  localMicroEnabled: boolean;
  toggleMicroEnabled: () => void
}) {

  return <RedCameraCardButton
    style={{gridColumn: 4}}
    onClick={() => toggleMicroEnabled && toggleMicroEnabled()}
    showDisabledMedia={showDisabledMedia}
    inOn={!microEnabled}
    inLocalOn={!localMicroEnabled}
    icon={pc ? <AnimatedMicro pc={pc}/> : KnownIcons.micro_filled}
  />
}

function AnimatedMicro({ pc }: { pc?: PeerConnection }) {

  const ref = useRef<SVGRectElement>();
  const frameRef = useRef<number>();

  useEffect(() => {

    let bufferLength = pc?.analyserNode.frequencyBinCount;
    let dataArray = new Uint8Array(bufferLength);
    let value = 0;
    function animate() {
      value = Math.max(0, value - .1);
      pc?.analyserNode.getByteTimeDomainData(dataArray);
      let min = 128, max = 0;
      for (let d in dataArray){
        min = Math.min(dataArray[d], min)
        max = Math.max(dataArray[d], max)
      }
      value += (max-min-1)/128
      value = Math.max(0, Math.min(value, 1))
      ref.current.setAttribute('y', "" + (32 - value*32));
      frameRef.current = requestAnimationFrame(animate);
    }

    frameRef.current = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(frameRef.current);
    };
  }, [pc]);


  return <>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <mask id="mic_mask">
          <rect x="13" y="8" width="6" height="10" rx="3" fill="yellow" stroke="white" strokeWidth="2" strokeLinecap="round"/>
          <path d="M10 14V15C10 18.3137 12.6863 21 16 21V21C19.3137 21 22 18.3137 22 15V14" stroke="white" strokeWidth="2" strokeLinecap="round"/>
          <path d="M16 24V21" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        </mask>
      </defs>
      <g mask="url(#mic_mask)">
        <rect fill="gray" width="32" height="32"/>
        <rect ref={ref}  y="0" width="32" height="32" fill="red"/>
      </g>
    </svg>
  </>
}
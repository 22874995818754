import React from "react";
import { Grid } from '@material-ui/core';
import { PiecesGroup } from '../PiecesGroup';
import { useModalStore } from '../../../store/modalStore';
import { HidingScrollbar } from '../../LowLevelComponents/HidingScrollbar';

export function ChessboardExtraPiecesDropdown(){
  const {closePopup} = useModalStore()
  return <div style={{margin: "10px 0", maxHeight: 400, overflow: 'hidden'}} onPointerDown={closePopup}>
    <HidingScrollbar>
      <Pieces groupName={"amega"} />
      <hr/>
      <Pieces groupName={"birds"} />
      <hr/>
      <Pieces groupName={"zones"} />
      <hr/>
      <Pieces groupName={"chars"} />
    </HidingScrollbar>
  </div>
}

function Pieces({groupName}:  { groupName: string }) {
  return <Grid style={{display:'flex', maxWidth: 280, flexWrap: "wrap"}}>
    <PiecesGroup groupName={groupName} />
  </Grid>
}
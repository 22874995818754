import Input from '../../components/LowLevelComponents/Input';
import React, { FormEvent, useState } from 'react';
import { Box, InputAdornment } from '@material-ui/core';
import { SvgImage } from '../../components/Icon/SvgImage';
import { KnownIcons } from '../../components/Icon/KnownIcons';
import { useLocalization } from '../../l10n/UseLocalization';

export function MaterialsPageSearchField() {

  const [search, setSearch] = useState<string>('');
  const { lesson } = useLocalization();

  return <Input
    value={search}
    onChange={(e: FormEvent<HTMLInputElement>) => setSearch(e.currentTarget.value)}
    fullWidth
    label={lesson.search}
    endAdornment={<InputAdornment position='end' style={{paddingRight: 5}}>
      {search ?
        <SvgImage icon={KnownIcons.cross} withHover onClick={() => setSearch('')} /> :
        <SvgImage icon={KnownIcons.magnifying_glass} style={{pointerEvents: "none"}}/>
      }
    </InputAdornment>}
  />;
}
import React from 'react';
import { Grid } from '@material-ui/core';
import { RegisterSteps } from './RegisterSteps';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { LoginPageContainer } from '../../components/LoginPageContainer/LoginPageContainer';

export function RegisterPage() {
  return <PageTemplate header={false}>
    <RegisterSteps />
  </PageTemplate>;
}

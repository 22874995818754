const theme = {
  // Цвета
  colors: {
    // Общие
    common: {
      black: '#000000',
      white: '#ffffff',
      chess1: '#000000',
      chess2: '#ffffff',
      chessboard1: '#CD9D76',
      chessboard2: '#F8E1C0',
      chessboard3: '#97B7A4',
      chessboard4: '#D1E8DC',
      chessboard5: '#93B4C8',
      chessboard6: '#D4DFE6',
      yellow: '#FFFF00',
    },
    // Светлая тема
    light: {
      switchHandleOffColor: '#FFFFFF',
      switchHandleOnColor: '#007AF3',
      switchOffBorderColor: '#D2D2D2',
      background: '#FFFFFF',
      header: '#FFFFFFAA',
      hover: '#F2F2F2',
      iconLight: '#D2D2D2',
      iconMain: '#868686',
      iconSecondary: '#504848',
      textMain: '#000000',
      button: '#0071E3',
      buttonHover: '#007AF3',
      select: '#CFE7F8',
      indicator1: '#27AE60',
      indicator2: '#F2994A',
      chessboardMuted1: '#E5C2A4',
      chessboardMuted2: '#F8E1C0',
      chessboardGray1: '#D2D2D2',
      chessboardGray2: '#F2F2F2',
      error1: '#FF0000',
      error2: '#FFF0F0',
      backcam: 'rgba(255, 255, 255, 0.2)',
      orange: '#EF9033',
      sky: '#40A7E3',
      green: '#27AE60',
      violet: '#9978C8',
      focus: '#cfe7f8',
    },
    // Темная тема
    dark: {
      switchHandleOffColor: '#18191E',
      switchHandleOnColor: '#007AF3',
      switchOffBorderColor: '#555555',
      background: '#2C2C2C',
      header: '#2C2C2CAA',
      hover: '#484848',
      iconLight: '#555555',
      iconMain: '#939393',
      iconSecondary: '#fff',
      textMain: '#DCDCDC',
      button: '#5198FF',
      buttonHover: '#007AF3',
      select: '#1B3B5B',
      indicator1: '#EF9033',
      indicator2: '#F2994A',
      chessboardMuted1: '#AC917A',
      chessboardMuted2: '#BEAF99',
      chessboardGray1: '#6F6969',
      chessboardGray2: '#868585',
      error1: '#EF2C53',
      error2: '#632636',
      backcam: 'rgba(0, 0, 0, 0.5)',
      orange: '#EF9033',
      sky: '#40A7E3',
      green: '#27AE60',
      focus: '#1b3b5b',
      violet: '#9978C8',
    },
  },
  // Шрифты
  fonts: {
    large: 'normal 400 44px/52px Roboto',
    bigBold: 'normal 700 32px/38px Roboto',
    normalBold: 'normal 700 17px/20px Roboto',
    normal: 'normal 400 17px/20px Roboto',
    list: 'normal 400 15px/18px Roboto',
    smallBold: 'normal 700 15px/18px Roboto',
    small: 'normal 400 15px/18px Roboto',
    mini: 'normal 400 12px/14px Roboto',
  },
  // Отступы
  spacing: {
    mini: 4,
    small: 8,
    medium: 16,
    large: 28,
  },
};

export default theme;

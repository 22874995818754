import React from 'react';
import styled from 'styled-components';
import { RawSvg } from '../Icon/RawSvg';
import { KnownIcons } from '../Icon/KnownIcons';
import { Stack } from './Stack';
import { TextNode } from './TextNode';
import { useLocalization } from '../../l10n/UseLocalization';

const Card = styled(Stack)`
  display: flex;
  border: 2px dashed var(--color-icon-main);
  mask-image: linear-gradient(160deg, #fff 60%, #fff1 80%);
  cursor: pointer;
  transition: 0.5s;
  
  & .text {
    justify-content: end;
    align-items: start;
    padding: 15px 20px;   
  }

  mask-size: 200% 100%;
  mask-position: 100% 0;
  &:hover {
    mask-position: 0 0;
  }
  //margin: 3px;
  //box-sizing: border-box;
  border-radius: 8px;
`;

export function AddCard({onClick}:{onClick}) {

  const { lesson } = useLocalization();

  return <Card onClick={onClick}>
    <div style={{height: 144, overflow: "hidden", opacity:0.2}}>
      <RawSvg rawSvg={KnownIcons.plus} width={200} className={"bg"}/>
    </div>
    <TextNode className={"text"}>{lesson.add}</TextNode>
  </Card>
}

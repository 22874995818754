import React from 'react';
import { useModalStore } from '../../store/modalStore';
import { ClickAwayListener, Popper } from '@material-ui/core';
import styled from 'styled-components';
import { ThemeVariables } from '../../app/StyledApp';

const StyledDropdown = styled.div`
  padding: 8px 10px;
  background-color: ${({ theme }) => theme.currentTheme.background};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.currentTheme.hover};
`;

export function PopupMenuContainer() {
  const {popup, closePopup} = useModalStore();
  if (!popup)
    return null;
  return <Popper open={!!popup} anchorEl={popup.anchorEl} style={{zIndex:1111}} placement={"bottom-start"} >
    <ClickAwayListener onClickAway={closePopup}>
      <ThemeVariables>
        <StyledDropdown style={{margin:"10px -10px"}} onClick={closePopup}>
          {popup.content}
        </StyledDropdown>
      </ThemeVariables>
    </ClickAwayListener>
  </Popper>
}
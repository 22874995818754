import React, { CSSProperties, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { useLessonStore } from '../store/lesson/LessonStore';
import { useLessonUiStore } from '../store/lesson-ui/LessonUiStoreImpl';
import { TabsLayout } from '../store/lesson-ui/LessonUiStoreApi';

interface IStyledContainer {
  gridTemplateColumns: CSSProperties['gridTemplateColumns'];
  gridTemplateRows: CSSProperties['gridTemplateRows'];
  gridTemplateAreas: CSSProperties['gridTemplateAreas'];
}

const StyledContainer = styled.div<IStyledContainer>`
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 0;
  grid-template-rows: ${({ gridTemplateRows }) => gridTemplateRows};
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  grid-template-areas: ${({ gridTemplateAreas }) => gridTemplateAreas};
  min-height: calc(100vh - 165px);
`;

function leftTabIsCollapsed(tabsLayout: TabsLayout) {
  return !tabsLayout.slots.topLeft.some(tab => tabsLayout.visible[tab]);

}

function rightTabIsCollapsed(tabsLayout: TabsLayout) {
  return !tabsLayout.slots.topRight.some(tab => tabsLayout.visible[tab]);
}

export function LessonDesktop(props: PropsWithChildren<{}>) {
  const { studentViewSettings, isStudentLayout, demonstrationState } = useLessonStore();
  const { tabsLayout } = useLessonUiStore();
  const shouldShowMovesSection = isStudentLayout ? studentViewSettings?.moves : true;
  const shouldShowQuestionSection = isStudentLayout ? studentViewSettings?.tasks : true;
  const shouldShowChessTools = isStudentLayout ? studentViewSettings?.toolbar : true;
  const shouldShowCameras = isStudentLayout ? studentViewSettings?.cameras : true;
  const shouldShowChatSection = isStudentLayout ? studentViewSettings?.chat : true;

  const styles: {
    gridTemplateColumns: CSSProperties['gridTemplateColumns'];
    gridTemplateAreas: CSSProperties['gridTemplateAreas'];
    gridTemplateRows: CSSProperties['gridTemplateRows'];
  } = {
    gridTemplateAreas: 'none',
    gridTemplateColumns: 'none',
    gridTemplateRows: `${shouldShowChessTools ? 0 : 1}fr auto auto 0fr`,
  };

  styles.gridTemplateColumns = 'repeat(12, 1fr)';

  let leftTabsHiddenOrCollapsed =
    (!shouldShowQuestionSection && !shouldShowMovesSection) ||
    leftTabIsCollapsed(tabsLayout) ||
    (isStudentLayout && demonstrationState);

  let rightTabsHiddenOrCollapsed =
    (!shouldShowCameras && !shouldShowChatSection) ||
    rightTabIsCollapsed(tabsLayout);


  function online_wide() {
    if (leftTabsHiddenOrCollapsed && rightTabsHiddenOrCollapsed) {
      styles.gridTemplateAreas = `
        'L T T T T T T T T T T R' 
        'L C C C C C C C C C C R' 
        'L C C C C C C C C C C R'
      `;
    } else if (leftTabsHiddenOrCollapsed) {
      styles.gridTemplateAreas = `
        'L T T T T T T T T R R R' 
        'L C C C C C C C C R R R' 
        'L C C C C C C C C R R R'
      `;
    } else if (rightTabsHiddenOrCollapsed) {
      styles.gridTemplateAreas = `
        'L L L T T T T T T T T R' 
        'L L L C C C C C C C C R' 
        'L L L C C C C C C C C R'
      `;
    } else {
      styles.gridTemplateAreas = `
        'L L L T T T T T T R R R' 
        'L L L C C C C C C R R R' 
        'L L L C C C C C C R R R'
      `;
    }
  }


  online_wide();


  return (
    <StyledContainer{...styles} className='lesson-view-wrapper'>
      {props.children}
    </StyledContainer>
  );
}
